import Swal from 'sweetalert2'
import {postData} from './api'
import './confirmation.css'

interface Props {
  url: string
  payload: {}
  resetForm: any
  clearWysiwyg?: any
  clearReactSelect?: any
}

export async function createConfirmationDialog({
  url,
  payload,
  resetForm,
  clearWysiwyg,
  clearReactSelect,
}: Props) {
  try {
    const response = await postData(url, payload, {
      headers: {
        'Content-Type': 'multipart-formdata',
      },
    })
    let action = await Swal.fire({
      title: 'Great!',
      html: `<p>${response.details}</p>`,
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Go to Overview',
      cancelButtonText: 'Dismiss',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-2',
        confirmButton: 'order-1 right-gap',
      },
    })
    resetForm({})
    if (clearWysiwyg) {
      clearWysiwyg()
    }
    if (clearReactSelect) {
      clearReactSelect()
    }
    return action
  } catch (e: any) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: e.response.data?.details || e.response.data?.detail || 'Something went wrong',
    })
  }
}

export function createSuccessDialog() {
  return Swal.fire('Great!', 'Done', 'success')
}

export function createErrorDialog(text: string = 'Something went wrong') {
  return Swal.fire({
    icon: 'error',
    title: 'Oops...',
    html: text,
  })
}

export function createUpdatedDialog() {
  return Swal.fire('Great!', 'The account has been updated', 'success')
}

export function createUpdatedDialogCustomBtn(id = '') {
  return Swal.fire({
    title: 'Great!',
    icon: 'success',
    customClass: {
      confirmButton: 'close-drawer-confirm-btn',
    },
    confirmButtonText: `<span id=${id} class="btn-content">Ok</i>`,
    // Just a temporary solution
    didClose: () => {
      // window.location.reload()
    },
  })
}

export function createLoadingDialog() {
  return Swal.showLoading()
}

export async function createDeletionDialog() {
  let value = await Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'Yes, delete it!',
  })
  return value
}
