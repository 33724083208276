import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideReader() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        // icon='/media/icons/duotune/art/art002.svg'
        icon='/media/icons/duotune/abstract/abs029.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='text-white text-uppercase fs-8 ls-1'>Users</span>
        </div>
      </div> */}

      <AsideMenuItem
        to='/students/list'
        title='Students'
        icon='/media/icons/duotune/communication/com006.svg'
        // icon='/media/icons/custom/sidebar/supervisor.svg'
      />

      <AsideMenuItem
        to='/marks/list'
        title="Students' Marks"
        icon='/media/icons/duotune/graphs/gra005.svg'
      />

      {/* <AsideMenuItemWithSub
        to='/feedbacks'
        title='Feedbacks'
        icon='/media/icons/duotune/communication/com012.svg'
        // icon='/media/icons/custom/sidebar/feedbacks.svg'
      > */}
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen055.svg'
        to='/feedbacks'
        title='Feedbacks'
      />
      {/* </AsideMenuItemWithSub> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='text-white text-uppercase fs-8 ls-1'>Feedbacks</span>
        </div>
      </div> */}
      {/* <AsideMenuItem
        to='/feedbacks'
        title='Feedbacks'
        icon='/media/icons/custom/sidebar/feedbacks.svg'
      /> */}
    </>
  )
}
