/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {removeAuth, useAuth} from '../../../../app/modules/auth'
import {useDatastore} from '../../../../app/modules/auth/core/Data'
import {getNameInitials} from '../../../helpers/utils'

const HeaderUserMenu: FC = () => {
  const {logout} = useAuth()
  const {profileInfo} = useDatastore()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {profileInfo?.image ? (
              <img
                alt='Logo'
                src={profileInfo?.image}
                style={{objectFit: 'cover', objectPosition: 'top'}}
              />
            ) : (
              <div className='symbol symbol-40px'>
                <div className='symbol-label fs-2 fw-bold bg-danger text-inverse-danger'>
                  {getNameInitials(profileInfo?.full_name)}
                </div>
              </div>
            )}
          </div>

          <div className='text-truncate'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <span>{profileInfo?.full_name}</span>
              <span className='svg-icon svg-icon-1 svg-icon-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24px'
                  height='24px'
                  viewBox='0 0 24 24'
                  className='mh-50px'
                >
                  <path
                    d='M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z'
                    fill='#00A3FF'
                  ></path>
                  <path
                    className='permanent'
                    d='M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z'
                    fill='white'
                  ></path>
                </svg>
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {profileInfo?.username}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/profile/overview' className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      {profileInfo?.role === 'student' && (
        <div className='menu-item px-5 my-1'>
          <Link to='/change-password' className='menu-link px-5'>
            Change Password
          </Link>
        </div>
      )}

      <div className='menu-item px-5'>
        <Link
          onClick={() => {
            logout()
            removeAuth()
          }}
          to='/login'
          className='menu-link px-5'
        >
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
