import {Column} from 'react-table'
// import {InputCell} from './InputCell'
import {MarksCustomHeader} from './MarksCustomHeader'
import {SupMarksTableType} from '../MarksTable'
import clsx from 'clsx'
import {getNameInitials} from '../../../../../../../_metronic/helpers/utils'
// import {KTSVG} from '../../../../../../../_metronic/helpers'
import {EditMarksCell} from './EditMarksCell'
import {determineGrade} from '../../../../../../helpers/utils'

const marksColumnsReader: Column<SupMarksTableType>[] = [
  {
    Header: (props) => (
      <MarksCustomHeader
        tableProps={props}
        title='Name &amp; Email'
        className='ps-4 rounded-start min-w-175px'
      />
    ),
    accessor: 'name',
    Cell: ({...props}) => (
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
          <span>
            {
              <div className={clsx('symbol-label fs-3', `bg-light-primary`, `text-primary`)}>
                {getNameInitials(props.data[props.row.index]?.name)}
              </div>
            }
          </span>
        </div>
        <div className='d-flex flex-column'>
          <span className='text-gray-800 text-hover-primary mb-1'>
            {props.data[props.row.index]?.name}
          </span>
          <span>{props.data[props.row.index]?.email}</span>
        </div>
      </div>
    ),
  },
  {
    Header: (props) => (
      <MarksCustomHeader tableProps={props} title='Student ID' className='min-w-125px' />
    ),
    accessor: 'college_id',
  },
  {
    Header: (props) => (
      <MarksCustomHeader tableProps={props} title="Reader's Marks" className='min-w-125px' />
    ),
    accessor: 'reader_marks',
    Cell: ({...props}) => (
      <>
        <div className='d-flex justify-content-between flex-wrap'>
          <span className='badge badge-light-primary badge-outline'>Project: &nbsp;</span>
          <EditMarksCell
            data={props.data[props.row.index]?.reader_marks?.project}
            selectedStudentId={props.data[props.row.index]?.selectedStudentId ?? ''}
            selectedSheetId={process.env.REACT_APP_PROJECT_SHEET_ID ?? ''}
            refetchTable={props.data[props.row.index]?.refetchTable}
            type='reader'
          />
        </div>
        <div className='d-flex justify-content-between flex-wrap'>
          <span className='badge badge-light-info badge-outline'>Professionalism: &nbsp;</span>
          <EditMarksCell
            data={props.data[props.row.index]?.reader_marks?.professionalism}
            selectedStudentId={props.data[props.row.index]?.selectedStudentId ?? ''}
            selectedSheetId={process.env.REACT_APP_PROFESSIONALISM_SHEET_ID ?? ''}
            refetchTable={props.data[props.row.index]?.refetchTable}
            type='reader'
          />
        </div>
      </>
    ),
  },
  {
    Header: (props) => (
      <MarksCustomHeader tableProps={props} title="Supervisor's Marks" className='min-w-125px' />
    ),
    accessor: 'supervisor_marks',
    Cell: ({...props}) => (
      <>
        <div className='d-flex justify-content-between flex-wrap'>
          <span className='badge badge-light-primary badge-outline'>Project: &nbsp;</span>
          {props.data[props.row.index]?.supervisor_marks?.project}
        </div>
        <div className='d-flex justify-content-between flex-wrap'>
          <span className='badge badge-light-info badge-outline'>Professionalism: &nbsp;</span>
          {props.data[props.row.index]?.supervisor_marks?.professionalism}
        </div>
      </>
    ),
  },
  {
    Header: (props) => (
      <MarksCustomHeader tableProps={props} title='Final Marks' className='min-w-125px' />
    ),
    accessor: 'final_marks',
    Cell: ({...props}) => {
      const supervisorMarks =
        (props.data[props.row.index]?.supervisor_marks?.project || 0) * 0.75 +
        (props.data[props.row.index]?.supervisor_marks?.professionalism || 0) * 0.25
      const readerMarks =
        (props.data[props.row.index]?.reader_marks?.project || 0) * 0.75 +
        (props.data[props.row.index]?.reader_marks?.professionalism || 0) * 0.25
      return (
        <>
          <span className='badge badge-light-primary badge-outline'>Supervisor: </span>
          {supervisorMarks && `${supervisorMarks.toFixed(1)}% (${determineGrade(supervisorMarks)})`}
          <br />
          <span className='badge badge-light-danger badge-outline'>Reader:</span>{' '}
          {readerMarks && `${readerMarks.toFixed(1)}% (${determineGrade(readerMarks)})`}
        </>
      )
    },
  },
]

export {marksColumnsReader}
