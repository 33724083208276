/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const QuickLinks: FC = () => (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
      <h3 className='text-white fw-bold my-5'>Quick Links</h3>
    </div>

    <div className='row g-0'>
      <div className='col-6'>
        <Link
          to='/supervisors/list'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
        >
          <KTSVG
            path='/media/icons/duotune/coding/cod004.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Supervisors</span>
          <span className='fs-7 text-gray-400'>View All</span>
        </Link>
      </div>

      <div className='col-6'>
        <Link
          to='/students/list'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com014.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Students</span>
          <span className='fs-7 text-gray-400'>View All</span>
        </Link>
      </div>

      <div className='col-6'>
        <Link
          to='/readers/list'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'
        >
          <KTSVG
            path='/media/icons/duotune/coding/cod009.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Readers</span>
          <span className='fs-7 text-gray-400'>View All</span>
        </Link>
      </div>

      <div className='col-6'>
        <Link
          to='/fyp-coordinators/list'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen049.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Coordinators</span>
          <span className='fs-7 text-gray-400'>View All</span>
        </Link>
      </div>
    </div>
  </div>
)

export {QuickLinks}
