import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import SupervisorDetail from '../../../modules/supervisors/SupervisorDetail'

const supervisorsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Supervisors',
    path: '/supervisors',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SupervisorsPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path=':id'
        element={
          <>
            <PageTitle breadcrumbs={supervisorsBreadCrumbs}>Supervisors Detail</PageTitle>
            <SupervisorDetail />
          </>
        }
      />
      <Route index element={<Navigate to='/supervisors/list' />} />
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Route>
  </Routes>
)

export default SupervisorsPage
