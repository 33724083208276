/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {getNameInitials} from '../../../_metronic/helpers/utils'
import {PageTitle} from '../../../_metronic/layout/core'
import AssignedStudentsTableReader from '../../../_metronic/partials/widgets/custom/AssignedStudentsTableReader'
import {getAllSections, getAllSupervisors} from '../../helpers/api'
import {useAuth} from '../../modules/auth'
import {useDatastore} from '../../modules/auth/core/Data'
// import {BarChart} from '../../modules/supervisors/components/BarChart'
import {ReaderProfileModel, Student} from '../../portals/readers/_models'
import MostAttentiveStudents from './components/MostAttentiveStudents'
import UnattentiveStudents from './components/UnattentiveStudents'

const DashboardPage: FC = () => {
  const {setSupervisors, setGroups} = useDatastore()
  const {currentUser} = useAuth()
  const reader = currentUser as ReaderProfileModel

  const allStudents: Student[] = []

  reader.profile?.supervisors?.forEach((supervisor) => {
    allStudents.push(...(supervisor.students || []))
  })

  useEffect(() => {
    getAllSupervisors().then((value) => setSupervisors(value))
    getAllSections().then((value) => setGroups(value))
    // eslint-disable-next-line
  }, [])

  const topAttenders = [...allStudents].sort(
    (a, b) => (b.attendance_count || 0) - (a.attendance_count || 0)
  )

  const bottomAttenders = [...allStudents].sort(
    (a, b) => (a.attendance_count || 0) - (b.attendance_count || 0)
  )

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <Link to='/students/list' className='card bg-body hoverable card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <KTSVG
                className='svg-icon svg-icon-primary svg-icon-3x ms-n1'
                path='/media/icons/duotune/communication/com014.svg'
              />
              <div className='text-gray-900 fw-bolder fs-2 mb-2 mt-5'>{allStudents.length}</div>
              <div className='fw-bold text-gray-400'>Assigned Students</div>
            </div>
          </Link>
        </div>
        {/* <div className='col-xl-4'>
          <div className='card bg-body card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <span className='svg-icon svg-icon-primary svg-icon-3x ms-n1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect x={8} y={9} width={3} height={10} rx='1.5' fill='currentColor' />
                  <rect
                    opacity='0.5'
                    x={13}
                    y={5}
                    width={3}
                    height={14}
                    rx='1.5'
                    fill='currentColor'
                  />
                  <rect x={18} y={11} width={3} height={8} rx='1.5' fill='currentColor' />
                  <rect x={3} y={13} width={3} height={6} rx='1.5' fill='currentColor' />
                </svg>
              </span>
              <div className='text-gray-900 fw-bolder fs-2 mb-2 mt-5'>{0}</div>
              <div className='fw-bold text-gray-400'>Total Meetings</div>
            </div>
          </div>
        </div> */}
        <div className='col-xl-6'>
          <div className='card bg-body card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                {reader.profile?.supervisors?.length}
              </span>
              <div className='fw-bold text-gray-400 mb-2'>Assigned Supervisor</div>

              {/* start group*/}
              <div className='symbol-group symbol-hover flex-nowrap mt-5'>
                {reader?.profile?.supervisors?.map((e) => (
                  <Link key={e.id} to={`/supervisors/${e.id}`}>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip>{e.user?.full_name}</Tooltip>}
                    >
                      <div className='symbol symbol-35px symbol-circle'>
                        {e.user_image ? (
                          <img
                            alt='Pic'
                            src={e.user_image}
                            style={{objectFit: 'cover', objectPosition: 'top'}}
                          />
                        ) : (
                          <span className='symbol-label bg-warning text-inverse-warning fw-bolder'>
                            {getNameInitials(e.user?.full_name)}
                          </span>
                        )}
                      </div>
                    </OverlayTrigger>
                  </Link>
                ))}
                {/* <div
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='tooltip'
                  aria-label='Michael Eberon'
                  data-kt-initialized={1}
                >
                  <img alt='Pic' src='/metronic8/demo1/assets/media/avatars/300-11.jpg' />
                </div>
                <div
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='tooltip'
                  data-kt-initialized={1}
                >
                  <span className='symbol-label bg-primary text-inverse-primary fw-bold'>S</span>
                </div>
                <div
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='tooltip'
                  aria-label='Melody Macy'
                  data-kt-initialized={1}
                >
                  <img alt='Pic' src='/metronic8/demo1/assets/media/avatars/300-2.jpg' />
                </div>
                <div
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='tooltip'
                  data-kt-initialized={1}
                >
                  <span className='symbol-label bg-danger text-inverse-danger fw-bold'>P</span>
                </div>
                <div
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='tooltip'
                  aria-label='Barry Walter'
                  data-kt-initialized={1}
                >
                  <img alt='Pic' src='/metronic8/demo1/assets/media/avatars/300-12.jpg' />
                </div>
                <a
                  href='#'
                  className='symbol symbol-35px symbol-circle'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_view_users'
                >
                  <span className='symbol-label bg-dark text-gray-300 fs-8 fw-bold'>+42</span>
                </a> */}
              </div>

              {/* end group*/}
            </div>
          </div>
        </div>
      </div>
      <div className='row gx-10 mb-5 mb-xxl-10'>
        <div className='mt-5 mt-md-0 col-md-6'>
          <UnattentiveStudents payload={bottomAttenders} total_meetings={0} />
          <div className='mb-5 mb-xxl-10'></div>
          <MostAttentiveStudents payload={topAttenders} total_meetings={0} />
        </div>
        <AssignedStudentsTableReader allStudents={allStudents} />
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
