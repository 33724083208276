import clsx from 'clsx'
import {FC} from 'react'

const NameAvatar: FC<{value: string; className?: string}> = ({value, className}) => {
  return (
    <div className={clsx('symbol symbol-circle overflow-hidden', className)}>
      <div className='symbol-label fs-1 bg-light-info text-info'>{value}</div>
    </div>
  )
}

export default NameAvatar
