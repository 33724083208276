import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useDatastore} from '../../../../../../modules/auth/core/Data'

interface Filter {
  q?: string
  section?: string
  supervisor?: string
}

const UsersListFilter = ({setFilterBySupervisorId}: {setFilterBySupervisorId: any}) => {
  const {state, updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const {supervisors} = useDatastore()
  const [section, setSection] = useState<string | undefined>()
  const [supervisorId, setSupervisorId] = useState<string | undefined>()

  console.log({supervisorId})

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const resetData = () => {
  //   setSection('')
  //   updateState({filter: undefined, ...initialQueryState})
  // }

  const resetData = () => {
    if (state?.filter) {
      const {filter: f} = state
      const obj: Filter = {}
      Object.assign(obj, f)
      if (obj.hasOwnProperty('q')) {
        updateState({filter: {q: obj?.q}, ...initialQueryState})
      } else {
        updateState({filter: undefined, ...initialQueryState})
      }
    }
    setSection('')
    setSupervisorId('')
    setFilterBySupervisorId(undefined)
  }

  // const filterData = () => {
  //   updateState({
  //     filter: {section},
  //     ...initialQueryState,
  //   })
  // }

  const filterData = () => {
    setFilterBySupervisorId(supervisorId)
    // if (state?.filter) {
    //   const {filter: f} = state
    //   Object.assign(f, {section, supervisor: supervisorId})
    //   updateState({
    //     filter: f,
    //     ...initialQueryState,
    //   })
    // } else {
    //   updateState({
    //     filter: {section, supervisor: supervisorId},
    //     ...initialQueryState,
    //   })
    // }
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='d-sm-flex align-items-center mb-5'>
            <label className='form-label fs-6 fw-bold me-4'>Supervisor:</label>
            <select
              onChange={({target}) => setSupervisorId(target.value?.toString())}
              className='form-select'
              defaultValue={supervisorId}
            >
              <option value='' disabled hidden>
                Select Supervisor
              </option>
              {supervisors.map((e, idx) => (
                <option key={idx} value={e?.id}>
                  {e.user.full_name}
                </option>
              ))}
            </select>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {UsersListFilter}
