import {useState, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
// For Collapsed Sidebar
import {useAuth} from '../../app/modules/auth'
import {AsideCollapsed} from './components/aside/AsideCollapsed'
import {DetailDrawerView} from '../../app/modules/students/user-management/users-list/components/drawer/DetailDrawer'
import {DrawerIdContext} from '../../app/modules/context/DrawerContext'
import {ID} from '../helpers'
import {initialContext} from '../../app/modules/context/DrawerContext'
import {EditDrawerView} from '../../app/modules/students/user-management/users-list/components/drawer/EditDrawer'

const MasterLayout = () => {
  const location = useLocation()
  const {currentUser} = useAuth()

  // For collapsing sidebar in supervisor and reader portal
  const hasNumberPattern = /\d/
  const role = currentUser?.profile?.user?.user_type

  // For Drawer Context
  const [drawerUserId, setDrawerUserId] = useState<ID>(initialContext.drawerUserId)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  const checkRoleAndRoute = () => {
    if (
      (role === 'supervisor' || role === 'reader') &&
      location.pathname.includes('/feedbacks/') &&
      hasNumberPattern.test(location.pathname)
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <PageDataProvider>
      {/* <div className='aside-collapsed-page page d-flex flex-row flex-column-fluid'> */}
      <div
        className={`${
          checkRoleAndRoute() && 'aside-collapsed-page'
        } page d-flex flex-row flex-column-fluid`}
      >
        {checkRoleAndRoute() ? <AsideCollapsed /> : <AsideDefault />}
        {/* <AsideDefault /> */}
        {/* For Drawers in User Detail and Edit */}
        <DrawerIdContext.Provider value={{drawerUserId, setDrawerUserId}}>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
                <EditDrawerView />
                <DetailDrawerView canEditOverview={role === 'reader' ? false : true} />
              </div>
            </div>
            <Footer />
          </div>
        </DrawerIdContext.Provider>
        {/* <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
              <div
                id='kt_test_drawer'
                className='bg-white row'
                data-kt-drawer='true'
                data-kt-drawer-activate='true'
                data-kt-drawer-toggle='#kt_test_drawer_button'
                // data-kt-drawer-close='#kt_test_drawer_close'
                data-kt-drawer-width="{default:'300px', 'sm': '400px', 'md': '500px', 'lg': '900px'}"
              >
                <div className='row ps-10 pe-5 pt-12 pb-6 position-relative'>
                  <p>Test Drawer</p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div> */}
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
