import clsx from 'clsx'
import InputValidationMessage from './InputValidationMessage'

interface Props {
  formik: any
  type?: string
  placeholder?: string
  label: string
  isRequired?: boolean
  children?: any
  disabled?: boolean
}
const InputFormik = ({
  formik,
  type,
  placeholder,
  isRequired = false,
  label,
  disabled = false,
}: Props) => {
  return (
    <>
      <label className={clsx('fs-6 fw-bold mb-2', !disabled && isRequired && 'required')}>
        {label}
      </label>
      <input
        {...formik.getFieldProps(label)}
        type={type || 'text'}
        className={clsx(
          'form-control',
          {'is-invalid': formik.touched[label] && formik.errors[label]},
          {
            'is-valid': formik.touched[label] && !formik.errors[label],
          }
        )}
        placeholder={placeholder}
        name={[label]}
        disabled={disabled}
      />
      {formik.touched[label] && formik.errors[label] && (
        <InputValidationMessage>{formik.errors[label]}</InputValidationMessage>
      )}
    </>
  )
}

export default InputFormik
