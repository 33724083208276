import {CSSProperties} from 'react'
import {StylesConfig} from 'react-select'

export type SelectOptionType = {
  label: string
  value: string
}

const customControlStyles: CSSProperties = {
  color: 'red',
  // backgroundColor: '#f5f8fa',
  // borderColor: '#f5f8fa',
  padding: '0.19rem 0.225rem',
  fontSize: '1.15rem',
}

type IsMulti = boolean

export const selectStyle: StylesConfig<SelectOptionType, IsMulti> = {
  // menu: (provided, state) => ({
  //   color: '#5e6278',
  // }),
  control: (provided, _) => {
    return {
      ...provided,
      ...customControlStyles,
    }
  },
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  multiValue: (provided, _) => {
    return {
      ...provided,
      backgroundColor: '#0052cc1a',
    }
  },
  multiValueLabel: (provided, _) => {
    return {
      ...provided,
      color: '#0052cc',
    }
  },
  multiValueRemove: (provided, _) => {
    return {
      ...provided,
      color: '#0052cc',
      ':hover': {
        backgroundColor: '#0052cc',
        color: 'white',
      },
    }
  },
}
