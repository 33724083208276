import axios, {AxiosRequestConfig} from 'axios'
import {Marks, setupAxios, StudentsMarks} from '../modules/auth'

setupAxios(axios)

export async function postData(url: string, data: any, options?: AxiosRequestConfig) {
  return (
    await axios.post(url, data, {
      baseURL: process.env.REACT_APP_API_URL,
      ...options,
    })
  ).data
}

export async function updateData(url: string, data: any, options?: AxiosRequestConfig) {
  return (
    await axios.patch(url, data, {
      baseURL: process.env.REACT_APP_API_URL,
      ...options,
    })
  ).data
}

export async function fileUpload(url: string, data: any) {
  return await axios.post(url, data, {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function getAllSupervisors() {
  return (
    await axios.get('/supervisor/?limit=100', {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data.results
}

export async function getAllSections() {
  return (
    await axios.get('/org/section/?limit=100', {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data.results
}

export async function getFeedbackSheet() {
  return (
    await axios.get('/feedbacksheet/', {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data.results
}

export async function getStudentsFeedback(id: string) {
  return (
    await axios.get(`/student/${id}/feedback/`, {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data
}

export async function getStudentFeedbackReport(id: string) {
  return await axios.get(`/student/${id}/exportreport/`, {
    responseType: 'blob',
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/zip',
    },
  })
}

export async function getStudentsFeedbackBySheetId(id: string, sheetId: string) {
  return (
    await axios.get(`/student/${id}/feedback/?sheet=${sheetId}`, {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data
}

export async function deleteUsers(users: number[], user_type: string) {
  return await axios.post(
    '/user/bulk/delete/',
    {
      ids: users,
      user_type,
    },
    {baseURL: process.env.REACT_APP_API_URL}
  )
}

export async function deleteFeedbackSheet(id: number) {
  return await axios.delete(`/feedbacksection/${id}/`, {baseURL: process.env.REACT_APP_API_URL})
}

export async function deleteComment(id: number) {
  return await axios.delete(`/comment/${id}/`, {baseURL: process.env.REACT_APP_API_URL})
}

export async function deleteFeedback(id: number) {
  return await axios.delete(`/feedback/${id}/`, {baseURL: process.env.REACT_APP_API_URL})
}

export async function getGlobalSearchResults(query: string) {
  return (
    await axios.get(`/search/?q=${query}`, {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data
}

// export async function getPreviewFeedback(studentId: string, sheetId: string) {
//   return (
//     await axios.get(`/student/${studentId}/previewfeedback/?sheet=${sheetId}`, {
//       baseURL: process.env.REACT_APP_API_URL,
//     })
//   ).data
// }

export async function getPreviewFeedback(studentId: string, sheetId: string) {
  return await axios.get(`/student/${studentId}/previewfeedback/?sheet=${sheetId}`, {
    responseType: 'blob',
    baseURL: process.env.REACT_APP_API_URL,
  })
}

export async function giveMarks(
  studentId: string,
  marks: string,
  feedbackSectionId: string,
  isFinal: boolean = false
) {
  const payload = isFinal
    ? {marks, feedback_section: feedbackSectionId, is_final_marks: true}
    : {marks, feedback_section: feedbackSectionId}
  return (
    await axios.post(`/student/${studentId}/mark_student/`, payload, {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data
}

export async function getAllMarksBySheet(studentId: string, sheetId: string) {
  return (
    await axios.get<Marks>(`student/${studentId}/get_all_markings/?sheet=${sheetId}`, {
      baseURL: process.env.REACT_APP_API_URL,
    })
  ).data
}

export async function getStudentsMarks(
  sheetId: string,
  offset: number,
  searchQuery?: string,
  sort?: string,
  order?: 'asc' | 'desc',
  filterBySupervisor?: number
) {
  let supervisorId = {}
  if (filterBySupervisor) supervisorId = {supervisor_id: filterBySupervisor}
  return (
    await axios.get<StudentsMarks>(`marking/all/?limit=15&offset=${offset}&sheet=${sheetId}`, {
      baseURL: process.env.REACT_APP_API_URL,
      params: {
        q: searchQuery || undefined,
        sort,
        order,
        ...supervisorId,
      },
    })
  ).data
}

// https://fss-backend.asparksys.cloud/api/latest-feedback-sheet/status/
export async function bulkDownloadStatus() {
  try {
    const res = await axios.get<any>('/latest-feedback-sheet/status/', {
      baseURL: process.env.REACT_APP_API_URL,
    })
    return res.data
  } catch (e) {
    return await startBulkDownload()
  }
}

export async function startBulkDownload() {
  const res = await axios.get('/feedback-sheet/start/export/', {
    baseURL: process.env.REACT_APP_API_URL,
  })
  return res.data
}

export async function getBatches() {
  const res = await axios.get('/batch/', {
    baseURL: process.env.REACT_APP_API_URL,
  })
  return res.data
}

export async function addNewBatch(name: string) {
  const res = await axios.post(
    '/batch/',
    {name: name},
    {
      baseURL: process.env.REACT_APP_API_URL,
    }
  )
  return res.data
}

export async function changeBatch(id: number) {
  const res = await axios.patch(
    `/batch/${id}/`,
    {is_active: true},
    {
      baseURL: process.env.REACT_APP_API_URL,
    }
  )
  return res.data
}
