import {FC} from 'react'
import {ColumnInstance} from 'react-table'
// import {RootUser} from '../../core/_models'
import {SupMarksTableType} from '../MarksTable'

type Props = {
  column: ColumnInstance<SupMarksTableType>
}

const MarksCustomHeaderColumn: FC<Props> = ({column}) => {
  return (
    <>
      {column.Header && typeof column.Header === 'string' ? (
        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
      ) : (
        column.render('Header')
      )}
    </>
  )
}

export {MarksCustomHeaderColumn}
