import * as Yup from 'yup'
import {useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import PasswordField from './components/ui/PasswordField'
import styles from './styles/styles.module.css'
import {resetPassword} from './core/_requests'
import InputValidationMessage from '../../components/ui/InputValidationMessage'
import {password, confirmPassword} from '../../../_metronic/helpers/yupSchema'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios'
import ConfirmPasswordField from './components/ui/ConfirmPasswordField'
import {
  getForgotPwEmail,
  removeForgotPwEmail,
  getForgotPwKey,
  removeForgotPwKey,
} from '../../storage'
import Swal from 'sweetalert2'

const resetPwSchema = Yup.object().shape({
  //   email,
  password,
  confirmPassword,
  token: Yup.string().required(),
})

const ResetPassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  //   const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues: {
      //   email: '',
      password: '',
      confirmPassword: '',
      token: '',
    },
    validationSchema: resetPwSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const email = getForgotPwEmail()
        const key = getForgotPwKey()
        if (email && key) {
          const res = await resetPassword(email, values.password, values.token, key)
          if (res) {
            // Remove local storage values for reset password
            removeForgotPwEmail()
            removeForgotPwKey()
            // Success alert
            Swal.fire({
              icon: 'success',
              title: 'Password Reset',
              confirmButtonText: 'Ok',
              text: 'You can Login with your new password.',
            }).then((result) => {
              if (result.isConfirmed) navigate('/login')
            })
          }
        } else {
          throw new Error('OTP expired!')
        }
        setLoading(false)
        navigate('/login')
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401) {
            setStatus(e.response.data.detail)
          } else {
            setStatus('An unknown error occurred!')
          }
        } else {
          if (e?.message) {
            setStatus(e?.message)
            // Failure alert
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              confirmButtonText: 'Resend OTP',
              text: e?.message || 'Something went wrong',
            }).then((result) => {
              if (result.isConfirmed) navigate('/forgot-password')
            })
          } else {
            setStatus('An unknown error occurred!')
          }
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <div className={styles.root}>
      <img
        src='/media/custom/hck-bg-white.svg'
        alt='hck logo'
        className={`${styles.main_background_hck} ${styles.main_background_hck_top}`}
      />
      <img
        src='/media/custom/hck-bg-white.svg'
        alt='hck logo'
        className={`${styles.main_background_hck} ${styles.main_background_hck_bottom}`}
      />
      <main className={styles.main}>
        <div className={styles.main_inner_div}>
          <div className={styles.main_inner_left}>
            <div className={styles.main_inner_headline}>
              <h2 className={styles.main_inner_subtitle}>Education | Collaboration | Optimized</h2>
              <h1 className={styles.main_inner_title}>FYP Supervision System</h1>
            </div>
            <img
              className={styles.hck_logo_hero}
              src='/media/custom/hck-bg-white.svg'
              alt='herald logo'
            />
            <img src='/media/custom/laptop.png' alt='macbook' className={styles.macbook} />
          </div>
          <div className={styles.main_inner_right}>
            <img
              src='/media/logos/hck-full.png'
              alt='banner logo'
              className={styles.login_logo_image}
            />
            <h1 className={styles.login_text}>
              <span className={styles.login_underlined_text}>Reset</span> Password
            </h1>

            <div className='mb-4'>
              <div className='d-flex align-items-center rounded py-5 px-5 bg-light-success'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon svg-icon-2x svg-icon-success me-5'
                />
                <div className='text-gray-700 fw-bold fs-6'>
                  Enter the OTP that you received in your email along with your new password.
                </div>
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <label className='d-block mb-4'>
                <span className={styles.field_required}>OTP Token</span>
                <input
                  {...formik.getFieldProps('token')}
                  className={clsx(
                    'form-control',
                    styles.form_control,
                    {'is-invalid': formik.touched.token && formik.errors.token},
                    {
                      'is-valid': formik.touched.token && !formik.errors.token,
                    }
                  )}
                  type='text'
                  name='token'
                />
                {formik.touched.token && formik.errors.token && (
                  <InputValidationMessage>{formik.errors.token}</InputValidationMessage>
                )}
              </label>
              <label className='d-block mb-4'>
                <span className={styles.field_required}>New Password</span>
                <PasswordField formik={formik} />
                {formik.touched.password && formik.errors.password && (
                  <InputValidationMessage>{formik.errors.password}</InputValidationMessage>
                )}
              </label>
              <label className='d-block mb-4'>
                <span className={styles.field_required}>Confirm Password</span>
                {/* <PasswordField formik={formik} /> */}
                <ConfirmPasswordField formik={formik} />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <InputValidationMessage>{formik.errors.confirmPassword}</InputValidationMessage>
                )}
              </label>
              <button
                type='submit'
                disabled={formik.isSubmitting || !formik.isValid}
                className='w-100 mr-15 btn btn-primary font-weight-bold py-2 px-3 px-xxl-5 my-1 d-block'
              >
                {!loading && <span className='indicator-label'>Reset Password</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                    Please wait...
                  </span>
                )}
              </button>
            </form>

            {formik.status && (
              <div className='mb-4'>
                <div className='d-flex align-items-center rounded py-5 px-5 bg-light-warning'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon svg-icon-2x svg-icon-warning me-5'
                  />
                  <div className='text-gray-700 fw-bold fs-6'>{formik.status}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <footer className={styles.footer}>
        <div className={styles.footer_div}>
          <p className={styles.footer_p}>
            <a href='https://heraldcollege.edu.np/about-herald' target='_blank' rel='noreferrer'>
              About Us
            </a>
          </p>
          <p className={styles.footer_p}>
            <a href='/'>Help</a>
          </p>
          <p className={`${styles.footer_p} ${styles.footer_p_last}`}>
            <a href='/'>Contact</a>
          </p>
        </div>
      </footer>
    </div>
  )
}

export default ResetPassword
