import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {useEffect, useState} from 'react'
import {bulkDownloadStatus, startBulkDownload} from '../../../../app/helpers/api'
import {formatDateFromNow} from '../../../../app/helpers/utils'
import {toast} from 'react-toastify'

type Props = {
  show: boolean
  handleClose: () => void
}

const ExportReport: React.FC<Props> = ({show, handleClose}) => {
  const [data, setData] = useState<any>()
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    bulkDownloadStatus().then((res) => {
      setData({
        last_updated: res.last_updated,
        pdf_ready_count: res.pdf_ready_count || 0,
        student_count: res.student_count || 0,
      })
    })
  }, [show])

  return (
    <Modal aria-hidden='true' centered show={show} onHide={handleClose}>
      <div className='modal-content shadow-none'>
        <div className='container-xxl'>
          <div className='modal-header d-flex justify-content-between border-0'>
            <h3>Export Report</h3>
            <div className='btn btn-icon btn-sm btn-light-primary ms-2' onClick={handleClose}>
              <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
          <div className='modal-body'>
            <div className='row mb-10'>
              <label className='col-lg-4 fw-bold text-muted'>Last Updated</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6'>{formatDateFromNow(data?.last_updated)}</span>
              </div>
            </div>
            <div className='row mb-10' style={{alignItems: 'center'}}>
              <label className='col-lg-4 fw-bold text-muted'>Status</label>
              <div className='col-lg-8'>
                <div className='d-flex flex-column w-100 me-2'>
                  <div className='d-flex flex-stack mb-2'>
                    <span className='text-muted me-2 fs-7 fw-semibold'>
                      {Math.floor((data?.pdf_ready_count / data?.student_count) * 100)}%
                    </span>
                  </div>
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-primary'
                      role='progressbar'
                      style={{
                        width: `${Math.floor(
                          (data?.pdf_ready_count / data?.student_count) * 100
                        )}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer justify-content-between'>
            <button
              disabled={disabled}
              onClick={async () => {
                setDisabled(true)
                await startBulkDownload()
                setDisabled(false)
                toast.success('Re-generating, please wait...')
                handleClose()
              }}
              className='btn btn-light-info'
            >
              Re-Generate
            </button>
            {data?.pdf_ready_count === data?.student_count && (
              <a
                href={`${process.env.REACT_APP_API_URL}/student/report/export`}
                className='btn btn-light-primary'
              >
                Download
              </a>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ExportReport
