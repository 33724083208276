import {FC, useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import clsx from 'clsx'
import {getAllMarksBySheet, getFeedbackSheet, giveMarks} from '../../../../../../../helpers/api'
import {Feedback, Marks} from '../../../../../../../modules/auth'
import {toast} from 'react-toastify'

interface MarksInput {
  marks: string
  feedback_section: string
  isFinal?: boolean
}

const EditMarksModal: FC<{
  show: boolean
  handleClose: () => void
  selectedStudentId: string
  selectedSheetId: string
  refetchTable?: any
  type?: 'supervisor' | 'reader'
}> = ({
  show,
  handleClose,
  selectedStudentId,
  selectedSheetId,
  refetchTable,
  type = 'supervisor',
}) => {
  const [studentMarks, setStudentMarks] = useState<Marks | undefined>()
  const [feedbackSheet, setFeedbackSheet] = useState<Feedback[] | undefined>()
  // For Giving Marks
  const [mInput, setMInput] = useState<MarksInput[]>([])
  const [givingMarks, setGivingMarks] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)
  // For Identifying Individual Disabled Buttons
  const [recentClick, setRecentClick] = useState<string>('')
  const [recentChange, setRecentChange] = useState<number>()

  const getMarks = async () => {
    try {
      const sheets = await getFeedbackSheet()
      setFeedbackSheet(sheets)
      const marks = await getAllMarksBySheet(selectedStudentId, selectedSheetId)
      setStudentMarks(marks)
    } catch (e) {
      console.log(e)
    }
  }

  const handleGiveMarks = async (
    sectionId: string | undefined,
    // total_marks_covered: number | undefined,
    value: string | undefined,
    isFinal?: boolean
  ) => {
    if (value) {
      if (parseInt(value) >= 0 && parseInt(value) <= 100) {
        if (selectedStudentId && sectionId) {
          try {
            setGivingMarks(true)
            if (type === 'supervisor') {
              await giveMarks(selectedStudentId, value, sectionId, true) // true is for isFinal flag
            } else {
              await giveMarks(selectedStudentId, value, sectionId)
            }
            setGivingMarks(false)
            setRecentClick('')
            toast.success(`${isFinal ? 'Final ' : ''}Marks Added`)
            setRefetch(true)
            if (refetchTable) refetchTable()
          } catch (e) {
            setGivingMarks(false)
            toast.error('Oops! Something went wrong.')
          }
        }
      } else {
        throw Error('Invalid Marks')
      }
    }
  }

  const findMarksInput = (marksArr: MarksInput[], sectionId: string) => {
    return marksArr?.find((m) => m?.feedback_section === sectionId)
  }

  useEffect(() => {
    if (show) {
      getMarks()
    }
    // eslint-disable-next-line
  }, [show])

  useEffect(() => {
    if (refetch) {
      getMarks()
      setRefetch(false)
    }
    // eslint-disable-next-line
  }, [refetch])

  return (
    <Modal size='lg' centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add/Edit Marks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          {/* Heading */}
          <div className='col-12 col-md-6 mb-2'>
            <p className='fs-6 fw-bold mb-2 required'>Section</p>
          </div>
          <div className='col-12 col-md-6 mb-2'>
            <div>
              <p className='fs-6 fw-bold mb-2 required'>Marks</p>
            </div>
          </div>
          {/* Content */}
          {/* feedbackSheet?.length && studentMarks?.results?.length */}
          {feedbackSheet?.length
            ? feedbackSheet
                ?.filter((s) => s?.id?.toString() === selectedSheetId)
                ?.map((fs) =>
                  fs?.sections
                    ?.filter((s) => s?.total_marks_covered !== 0)
                    ?.map((sec, idxx) => (
                      <div className='row mb-4' key={idxx}>
                        <div className='col-12 col-md-6 d-flex flex-column'>
                          <span className='text-primary'>{sec?.name}</span>
                          <small className='d-flex align-items-center'>
                            Percentage Weightage:{' '}
                            <span className='text-muted fs-7 ms-1 fw-bold'>
                              {sec?.total_marks_covered}
                            </span>
                          </small>
                        </div>
                        <div className='col-12 col-md-6'>
                          <form
                            className='d-flex align-items-center'
                            onSubmit={(e) => {
                              e?.preventDefault()
                              setRecentClick(sec?.id?.toString())
                              const found = findMarksInput(mInput, sec?.id?.toString())
                              if (found) {
                                handleGiveMarks(
                                  sec?.id?.toString(),
                                  // sec?.total_marks_covered,
                                  found?.marks
                                )
                                  .then(() => setRecentChange(-1))
                                  .catch(() => toast.error('Invalid Marks'))
                              }
                            }}
                          >
                            {/* Putting the same data as defaultValue on "key" prop of div to re-render all of its children element as React doesn't re-render input when defaultValue changes. */}
                            <div
                              className='input-group'
                              key={
                                studentMarks?.results?.find((m) => m?.section_id === sec?.id)
                                  ?.marks ?? 0
                              }
                            >
                              <input
                                type='text'
                                className={clsx('form-control')}
                                placeholder='e.g. 20'
                                name={sec?.id?.toString()}
                                disabled={
                                  Boolean(recentChange) &&
                                  recentChange !== -1 &&
                                  recentChange !== sec?.id
                                }
                                // defaultValue={
                                //   studentMarks?.results?.find((m) => m?.section_id === sec?.id)
                                //     ?.marks ?? 0
                                // }
                                defaultValue={
                                  studentMarks?.results
                                    ?.filter((m) => m?.marked_by === type)
                                    ?.find((m) => m?.section_id === sec?.id)?.marks ?? 0
                                }
                                onChange={(e) => {
                                  setRecentChange(sec?.id)
                                  if (findMarksInput(mInput, sec?.id?.toString())) {
                                    setMInput(
                                      mInput?.map((m) => {
                                        if (m?.feedback_section === sec?.id?.toString()) {
                                          return {
                                            marks: e?.target?.value,
                                            feedback_section: sec?.id?.toString(),
                                          }
                                        } else {
                                          return m
                                        }
                                      })
                                    )
                                  } else {
                                    setMInput((prevState) => [
                                      ...prevState,
                                      {
                                        marks: e?.target?.value,
                                        feedback_section: sec?.id?.toString(),
                                      },
                                    ])
                                  }
                                  //*
                                }}
                              />
                              <span className='input-group-text' id='basic-addon1'>
                                / 100
                              </span>
                              {/* {type === 'supervisor' && (
                                <span className='input-group-text' id='basic-addon1'>
                                  <input
                                    type='checkbox'
                                    id={sec?.id?.toString()}
                                    checked={
                                      studentMarks?.results
                                        ?.filter((m) => m?.marked_by === type)
                                        ?.find((m) => m?.section_id === sec?.id)?.is_final
                                    }
                                    value={
                                      studentMarks?.results
                                        ?.filter((m) => m?.marked_by === type)
                                        ?.find((m) => m?.section_id === sec?.id)?.marks ?? 0
                                    }
                                    onChange={(e) => {
                                      handleGiveMarks(
                                        sec?.id?.toString(),
                                        // sec?.total_marks_covered,
                                        e?.target?.value,
                                        e?.target?.checked
                                      )
                                    }}
                                  />
                                  <label className='fs-7 ms-1' htmlFor={sec?.id?.toString()}>
                                    Is Final
                                  </label>
                                </span>
                              )} */}
                            </div>
                            {recentChange === sec?.id && (
                              <div className='ms-2 d-flex'>
                                <button
                                  // disabled={
                                  //   givingMarks && findMarksInput(mInput, sec?.id?.toString())
                                  //     ? true
                                  //     : false
                                  // }
                                  disabled={
                                    givingMarks && recentClick === sec?.id?.toString()
                                      ? true
                                      : false
                                  }
                                  className='btn btn-primary btn-sm'
                                  type='submit'
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    ))
                )
            : null}
          {/* <div className='col-12 col-md-6'>
            <span className='text-primary'>BCS Test</span>
          </div> */}
          {/* <div className='col-12 col-md-6'>
            <div className='input-group'>
              <input
                {...formik.getFieldProps('Marks')}
                type='text'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.Marks && formik.errors.Marks},
                  {
                    'is-valid': formik.touched.Marks && !formik.errors.Marks,
                  }
                )}
                placeholder='e.g. 20'
                name='Marks'
              />
              <span className='input-group-text' id='basic-addon1'>
                %
              </span>
            </div>
            {formik.touched.Marks && formik.errors.Marks && (
              <InputValidationMessage>{formik.errors.Marks}</InputValidationMessage>
            )}
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type='button' variant='secondary' onClick={handleClose}>
          Discard
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditMarksModal
