import {useMemo, useEffect, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {UsersListLoading} from '../components/loading/UsersListLoading'
// import {UsersListPagination} from '../components/pagination/UsersListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {marksColumnsSupervisor} from './columns/_marksColumnsSupervisor'
import {useQuery} from 'react-query'
import {getStudentsMarks} from '../../../../../helpers/api'
import {MarksCustomHeaderColumn} from './columns/MarksCustomHeaderColumn'
import {MarksCustomRow} from './columns/MarksCustomRow'
import ReactPaginate from 'react-paginate'
import '../components/pagination/pagination.css'
import {marksColumnsReader} from './columns/_marksColumnReader'
import {marksColumnsAdmin} from './columns/_marksColumnAdmin'
import {useQueryRequest} from '../core/MarksQueryReqProvider'

export interface SupMarksTableType {
  name?: string
  email?: string
  college_id?: string
  // project_supervisor_marks?: number
  // professionalism_supervisor_marks?: number
  supervisor_marks?: {
    project?: number
    professionalism?: number
  }
  reader_marks?: {
    project?: number
    professionalism?: number
  }
  final_marks?: {
    project?: number
    professionalism?: number
  }
  selectedSheetId?: string
  selectedStudentId?: string
  refetchTable?: any
}

interface Props {
  sheetQuery?: string
  searchQuery?: string
  setTotalCount: React.Dispatch<React.SetStateAction<number>>
  type?: 'supervisor' | 'reader' | 'admin'
  filterBySupervisorId?: number
}

const MarksTable = ({
  sheetQuery = '',
  searchQuery = '',
  setTotalCount,
  type = 'supervisor',
  filterBySupervisorId,
}: Props) => {
  const {state} = useQueryRequest()

  const columns = useMemo(() => {
    if (type === 'supervisor') return marksColumnsSupervisor
    else if (type === 'reader') return marksColumnsReader
    else return marksColumnsAdmin
    // eslint-disable-next-line
  }, [])
  const [tableData, setTableData] = useState<SupMarksTableType[]>([])
  // Refetch
  // const [refetch, setRefetch] = useState<boolean>(false)

  // const handleRefetch = (isRefetch: boolean) => {
  //   setRefetch(isRefetch)
  // }

  // Pagination
  const [offset, setOffset] = useState<number>(0)
  const [pageCount, setPageCount] = useState(1)
  const handlePageClick = (event: any) => {
    const pgOffset = 15 * event.selected
    if (typeof pgOffset === 'number') {
      setOffset(pgOffset)
    }
  }

  // API: Get Marks
  const {
    isLoading,
    isRefetching,
    data: marksData,
    refetch,
  } = useQuery(
    `marks-${sheetQuery}-${searchQuery}-${offset}-${state?.sort}-${state?.order}`,
    () => {
      return getStudentsMarks(
        sheetQuery,
        offset,
        searchQuery,
        state?.sort,
        state?.order,
        filterBySupervisorId
      )
    },
    // {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    {refetchOnWindowFocus: false}
  )

  useEffect(() => {
    refetch()
  }, [filterBySupervisorId])

  useEffect(() => {
    if (marksData?.results?.length) {
      setTableData(
        marksData?.results?.map((m) => ({
          name: m?.user?.full_name,
          email: m?.user?.username,
          college_id: m?.university_id,
          supervisor_marks: {
            project: m?.markings[process.env.REACT_APP_PROJECT_SHEET_ID!].supervisor_marks
              ? Math.round(m?.markings[process.env.REACT_APP_PROJECT_SHEET_ID!].supervisor_marks)
              : 0,
            professionalism: m?.markings[process.env.REACT_APP_PROFESSIONALISM_SHEET_ID!]
              .supervisor_marks
              ? Math.round(
                  m?.markings[process.env.REACT_APP_PROFESSIONALISM_SHEET_ID!].supervisor_marks
                )
              : 0,
          },
          reader_marks: {
            project: m?.markings[process.env.REACT_APP_PROJECT_SHEET_ID!].reader_marks
              ? Math.round(m?.markings[process.env.REACT_APP_PROJECT_SHEET_ID!].reader_marks)
              : 0,
            professionalism: m?.markings[process.env.REACT_APP_PROFESSIONALISM_SHEET_ID!]
              .reader_marks
              ? Math.round(
                  m?.markings[process.env.REACT_APP_PROFESSIONALISM_SHEET_ID!].reader_marks
                )
              : 0,
          },
          final_marks: {
            project: m?.markings[process.env.REACT_APP_PROJECT_SHEET_ID!].final_marks
              ? Math.round(m?.markings[process.env.REACT_APP_PROJECT_SHEET_ID!].final_marks)
              : 0,
            professionalism: m?.markings[process.env.REACT_APP_PROFESSIONALISM_SHEET_ID!]
              .final_marks
              ? Math.round(m?.markings[process.env.REACT_APP_PROFESSIONALISM_SHEET_ID!].final_marks)
              : 0,
          },
          selectedSheetId: sheetQuery,
          selectedStudentId: m?.id?.toString(),
          refetchTable: refetch,
        }))
      )
      if (marksData?.count) {
        setTotalCount(marksData?.count)
        setPageCount(marksData?.count / 15)
      }
    }
    // eslint-disable-next-line
  }, [marksData])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: tableData,
  })

  return (
    <>
      {(isLoading || isRefetching) && <UsersListLoading />}
      <KTCardBody className='py-4'>
        <div className='table-responsive mb-5'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 gs-0 bg-light'>
                {headers.map((column: ColumnInstance<SupMarksTableType>) => (
                  <MarksCustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<SupMarksTableType>, i) => {
                  prepareRow(row)
                  return <MarksCustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <CustomPagination /> */}
        <div className='row custom-pagination'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel='<'
            // renderOnZeroPageCount={null}
          />
        </div>
      </KTCardBody>
    </>
  )
}

export {MarksTable}
