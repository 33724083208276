import {createContext} from 'react'
import {ID} from '../../../_metronic/helpers'

interface QueryRequestContextProps {
  drawerUserId: ID
  setDrawerUserId: Function
}

export const initialContext: QueryRequestContextProps = {
  drawerUserId: undefined,
  setDrawerUserId: () => {},
}

// As Drawer Trigger button and Drawer are separate, we need context to pass user "id" to Drawer
// Creating user "id" context to pass on to Drawer for fetching user data for edit purpose.
export const DrawerIdContext = createContext<QueryRequestContextProps>(initialContext)
