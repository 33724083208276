import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useEffect, useState} from 'react'
import {Feedback} from '../../../../app/modules/auth'
import {getFeedbackSheet} from '../../../../app/helpers/api'

export function AsideMenuMain() {
  const intl = useIntl()
  const [feedbackSheets, setFeedbackSheets] = useState<Feedback[]>([])

  const getSheets = async () => {
    try {
      const feedbackSheets = await getFeedbackSheet()
      if (feedbackSheets) {
        setFeedbackSheets(feedbackSheets)
      }
    } catch (e) {
      setFeedbackSheets([])
    }
  }

  useEffect(() => {
    getSheets()
  }, [])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='text-white text-uppercase fs-8 ls-1'>Users</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/students'
        title='Students'
        icon='/media/icons/custom/sidebar/student.svg'
      >
        <AsideMenuItem to='/students/list' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/students/new' title='Add Student' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/supervisors'
        title='Supervisors'
        icon='/media/icons/custom/sidebar/supervisor.svg'
      >
        <AsideMenuItem to='/supervisors/list' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/supervisors/new' title='Add Supervisor' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/readers'
        title='Readers'
        icon='/media/icons/custom/sidebar/reader.svg'
      >
        <AsideMenuItem to='/readers/list' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/readers/new' title='Add Reader' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/fyp-coordinators'
        title='FYP Coordinators'
        icon='/media/icons/custom/sidebar/coordinator.svg'
      >
        <AsideMenuItem to='/fyp-coordinators/list' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/fyp-coordinators/new' title='Add FYP Coordinator' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/marks/list'
        title="Students' Marks"
        icon='/media/icons/duotune/graphs/gra005.svg'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='text-white text-uppercase fs-8 ls-1'>Console</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/feedbacks'
        title='Feedbacks'
        icon='/media/icons/custom/sidebar/student.svg'
      >
        {feedbackSheets?.length ? (
          <AsideMenuItem
            to={`/feedbacks/sheets/${feedbackSheets[0]?.id}`}
            title='Feedbacks Sheet'
            hasBullet={true}
          />
        ) : null}
        <AsideMenuItem to='/feedbacks/reports' title='Feedbacks Report' hasBullet={true} />
      </AsideMenuItemWithSub>
    </>
  )
}
