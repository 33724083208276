/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {AdminRoutes} from './AdminRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'
import Login from '../modules/auth/Login'
import ForgotPassword from '../modules/auth/ForgotPassword'
import ResetPassword from '../modules/auth/ResetPassword'
import {useAuth} from '../modules/auth'
import {SupervisorRoutes} from './SupervisorRoutes'
import {StudentRoutes} from './StudentRoutes'
import {ReaderRoutes} from './ReaderRoutes'

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const role = currentUser?.profile?.user?.user_type

  const routeHandler = () => {
    if (role === 'admin') {
      return <Route path='/*' element={<AdminRoutes />} />
    } else if (role === 'supervisor') {
      return <Route path='/*' element={<SupervisorRoutes />} />
    } else if (role === 'student') {
      return <Route path='/*' element={<StudentRoutes />} />
    } else if (role === 'reader') {
      return <Route path='/*' element={<ReaderRoutes />} />
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          {currentUser ? (
            <>
              <Route path='login' element={<Navigate to='/dashboard' />} />
              {routeHandler()}
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='login/*' element={<Login />} />
              <Route path='forgot-password' element={<ForgotPassword />} />
              <Route path='reset-password' element={<ResetPassword />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </>
          )}
          <Route index element={<Navigate to='/login' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
