import {FC, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import EditMarksModal from '../../components/modal/EditMarks'

type Props = {
  data: number | undefined
  selectedStudentId: string
  selectedSheetId: string
  refetchTable?: any
  type?: 'supervisor' | 'reader'
}

const EditMarksCell: FC<Props> = ({
  data,
  selectedStudentId,
  selectedSheetId,
  refetchTable,
  type = 'supervisor',
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const handleOpenModal = () => setIsEdit(true)
  const handleCloseModal = () => setIsEdit(false)

  return (
    <>
      <span className={`mb-0 cursor-pointer`} onClick={handleOpenModal}>
        <span className='border-bottom border-3 border-secondary me-2'>{data}</span>
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </span>
      <EditMarksModal
        show={isEdit}
        handleClose={handleCloseModal}
        selectedStudentId={selectedStudentId}
        selectedSheetId={selectedSheetId}
        refetchTable={refetchTable}
        type={type}
      />
    </>
  )
}

export {EditMarksCell}
