import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Student} from '../../../../app/portals/readers/_models'

const AssignedStudentsTable: FC<{allStudents: Student[]}> = ({allStudents}) => {
  return (
    <div className='col-md-6'>
      <div className='card shadow-sm mb-5' style={{height: '565px', overflow: 'scroll'}}>
        <div className='card-body'>
          <div className='hover-scroll-overlay-y position-relative'>
            <table className='table align-middle table-row-bordered gy-4'>
              <thead
                className='border-gray-200 fs-5 fw-bold sticky-top bg-white'
                style={{zIndex: 1}}
              >
                <tr>
                  <th className='ps-4 w-125px'>Student Id</th>
                  <th>Student Name</th>
                  <th>Attendance</th>
                </tr>
              </thead>
              <tbody className='fw-6 fw-bold text-gray-600'>
                {allStudents.map((e, idx) => (
                  <tr key={idx}>
                    <td className='ps-4'>
                      <span className='badge badge-light-success fs-7 fw-bolder'>
                        {e.university_id}
                      </span>
                    </td>
                    <td>
                      <Link className='text-muted text-hover-primary' to={`/students/${e?.id}`}>
                        {e.user?.full_name}
                      </Link>
                    </td>
                    <td>{e.attendance_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignedStudentsTable
