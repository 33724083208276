/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
// import {MOST_LEAST_ATTENDANCE_PERCENT} from '../../../helpers/constants'
import {Student} from '../../../portals/supervisors/_models'

interface Props {
  payload?: Student[]
  total_meetings?: number
}

const MostAttentiveStudents: FC<Props> = ({payload, total_meetings}) => {
  if (payload && payload.length > 3) payload = [payload[0], payload[1], payload[2]]
  return (
    <div className='card card-xxl-stretch mb-5 mb-md-0'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Most Attentive Students</span>
          <span className='text-muted fw-bold fs-7'>Students with most attendance</span>
        </h3>
      </div>
      <div className='card-body'>
        {payload?.map((e) => {
          const percent = ((e.attendance_count / (total_meetings || 1)) * 100).toFixed(0)
          // if (parseInt(percent) >= MOST_LEAST_ATTENDANCE_PERCENT.most) {
          // }
          return (
            <div key={e.id} className='row mb-4'>
              <div className='col-6'>
                <Link
                  to={`/students/${e?.id}`}
                  className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                >
                  {e.user.full_name}
                </Link>
                <span className='text-muted fw-bold d-block fs-7'>{e.university_id}</span>
              </div>
              <div className='col-6 d-flex align-items-center'>
                <div className='progress h-6px w-100'>
                  <div
                    className='progress-bar bg-success'
                    role='progressbar'
                    style={{width: `${percent}%`}}
                  />
                </div>
                <div className='d-flex flex-stack'>
                  <span className='text-muted ms-2 fs-7 fw-bold'>{percent}%</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MostAttentiveStudents
