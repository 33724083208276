const FORGOT_PASSWORD_EMAIL = 'fp-email'
const FORGOT_PASSWORD_KEY = 'fp-key'

// Email
export const setForgotPwEmail = (email: string) => {
  localStorage.setItem(FORGOT_PASSWORD_EMAIL, email)
}

export const getForgotPwEmail = () => {
  return localStorage.getItem(FORGOT_PASSWORD_EMAIL)
}

export const removeForgotPwEmail = () => {
  return localStorage.removeItem(FORGOT_PASSWORD_EMAIL)
}

// Key
export const setForgotPwKey = (key: string) => {
  localStorage.setItem(FORGOT_PASSWORD_KEY, key)
}

export const getForgotPwKey = () => {
  return localStorage.getItem(FORGOT_PASSWORD_KEY)
}

export const removeForgotPwKey = () => {
  return localStorage.removeItem(FORGOT_PASSWORD_KEY)
}
