import {FC, useState, useEffect, useContext} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import clsx from 'clsx'
import {useFormik} from 'formik'
import Skeleton, {SkeletonProps} from 'react-loading-skeleton'
import {DrawerIdContext} from '../../../../../context/DrawerContext'
import {getUserById} from '../../../users-list/core/_requests'
import {createErrorDialog} from '../../../../../../helpers/confirmation-dialog'
import {RootUser} from '../../core/_models'
import {
  getNameInitials,
  splitLanguagesForDropdown,
  programmingLanguages,
  getRandomBootstrapColor,
} from '../../../../../../../_metronic/helpers/utils'
import {useDatastore} from '../../../../../auth/core/Data'
import {updateData} from '../../../../../../helpers/api'
import {DonutChart} from '../../../../../students/components/DonutChart'
import {LatestFeedbacks} from '../../../../../students/components/LatestFeedbacks'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {CustomCreatableSelect} from '../../../../../../components/reusable-blocks/CustomSelect'
import WYSIWYG from '../../../../../../components/reusable-blocks/WYSIWYG'
import {selectStyle} from '../../../../../../../_metronic/helpers/SelectStyle'

interface Props {
  root: RootUser
}

export const DetailDrawerBtn: FC<Props> = ({root}) => {
  const {setDrawerUserId} = useContext(DrawerIdContext)

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <span
          id='kt_detail_drawer_button'
          className='cursor-pointer'
          onClick={() => setDrawerUserId(root.id)}
        >
          {root.user_image ? (
            <div className='symbol-label'>
              <img
                src={root.user_image}
                alt={root.user.full_name}
                className='w-100 h-100'
                style={{objectFit: 'cover', objectPosition: 'top'}}
              />
            </div>
          ) : (
            <div className={clsx('symbol-label fs-3', `bg-light-primary`, `text-primary`)}>
              {getNameInitials(root?.user?.full_name)}
            </div>
          )}
        </span>
      </div>
      <div className='d-flex flex-column'>
        <span
          id='kt_detail_drawer_button'
          className='text-gray-800 text-hover-primary mb-1 cursor-pointer'
          onClick={() => setDrawerUserId(root.id)}
        >
          {root.user.full_name}
        </span>
        <span>{root.user.username}</span>
      </div>
    </div>
  )
}

export const DetailDrawerView: FC<{canEditOverview?: boolean}> = ({canEditOverview = true}) => {
  type FormikValues = {
    name: string
    language: {label: string; value: string}[] | undefined
    description: string
  }

  const {drawerUserId} = useContext(DrawerIdContext)

  // const {id} = useParams()
  const [payload, setPayload] = useState<RootUser>()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const {profileInfo} = useDatastore()
  // Editing
  const [editing, setEditing] = useState(false)
  const [languages, setLanguages] = useState('')
  const [overviewValues, setOverviewValues] = useState<FormikValues>({
    name: '',
    description: '',
    language: [{label: '', value: ''}],
  })

  useEffect(() => {
    if (drawerUserId) {
      console.log(drawerUserId)
      getUserById(drawerUserId.toString())
        .then((res) => {
          setPayload(res)
          setLoading(false)
          return res
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              createErrorDialog('No such data found')
              navigate('/students/list')
            }
          }
        })
    }
  }, [drawerUserId, navigate])

  function getComponent({
    component,
    skeletonProps,
  }: {
    component: any
    skeletonProps?: SkeletonProps
  }) {
    if (loading) return <Skeleton {...skeletonProps} />
    return component
  }

  let avatar: any

  if (payload?.user_image) {
    avatar = (
      <img
        src={payload?.user_image}
        alt='student'
        className='h-125px mb-4'
        style={{objectFit: 'contain'}}
      />
    )
  } else {
    avatar = (
      <div className='symbol symbol-circle symbol-100px overflow-hidden me-3 mb-2'>
        <div className={clsx('symbol-label fs-1', `bg-light-danger`, `text-danger`)}>
          {getNameInitials(payload?.user.full_name)}
        </div>
      </div>
    )
  }

  // Editing
  const formik = useFormik<FormikValues>({
    initialValues: {
      name: '',
      language: [{label: '', value: ''}],
      description: '',
    },
    onSubmit: async (values) => {
      let str = ''
      const newLang = values?.language?.map((d) => ({label: d?.label, value: d?.value}))
      values.language = newLang
      // values.language.forEach((e) => (str += e.label))
      if (values.language)
        for (let i = 0; i < values.language.length; i++) {
          str += values.language[i].label
          if (i !== values.language.length - 1) str += ','
        }
      await updateData(`/student/${payload?.id}/`, {
        student_project: {
          name: values.name,
          language: str,
          description: values.description,
        },
      })
      setEditing(false)
      setOverviewValues(values)
      setLanguages(str)
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (payload?.student_project) {
      // overview state
      setOverviewValues({
        name:
          (payload?.student_project &&
            payload?.student_project?.length > 0 &&
            payload?.student_project![0].name) ||
          '-',
        description:
          (payload?.student_project &&
            payload?.student_project?.length > 0 &&
            payload?.student_project![0].description) ||
          '-',
        language: splitLanguagesForDropdown(payload?.student_project![0]?.language),
      })
      setLanguages(payload.student_project![0]?.language || '')
      formik?.setValues({
        name: payload?.student_project[0]?.name || '',
        language: splitLanguagesForDropdown(payload?.student_project![0]?.language),
        description: payload?.student_project[0]?.description || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  return (
    <div
      id='kt_detail_drawer'
      className='bg-white row'
      // className='bg-white row drawer drawer-end'
      data-kt-drawer='true'
      data-kt-drawer-activate='true'
      data-kt-drawer-toggle='#kt_detail_drawer_button'
      data-kt-drawer-close='#kt_detail_drawer_close'
      // data-kt-drawer-width="{default:'300px', 'sm': '500px', 'md': '600px', 'lg': '700px', 'xl': '900px', 'xxl': '1100px'}"
      data-kt-drawer-width="{default:'300px', 'sm': '400px', 'md': '500px', 'lg': '900px'}"
    >
      <div className='row ps-10 pe-5 pt-12 pb-6 position-relative'>
        <div
          id='kt_detail_drawer_close'
          className='btn btn-icon btn-active-light-primary position-absolute w-30px h-30px w-md-40px h-md-40px'
          style={{zIndex: 99, right: '1px', top: '1px'}}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr088.svg' className='svg-icon-1' />
        </div>
        <div className='col-lg-6'>
          <div
            className='card shadow-sm mb-5 mb-xl-5'
            style={{height: '400px', overflow: 'scroll'}}
          >
            <div className='card-body p-9 pb-5'>
              <div className='text-center'>
                {getComponent({
                  component: avatar,
                  skeletonProps: {height: 100, width: 100, borderRadius: '50%', className: 'mb-2'},
                })}
              </div>
              <div className='fw-bolder fs-4 text-dark'>
                {getComponent({component: payload?.user.full_name})}
              </div>
              <span className='d-block mb-4 text-primary'>
                {getComponent({component: payload?.user.username})}
              </span>
              <div className='row mb-2'>
                <label className='col-lg-4 fw-bold text-muted'>Student ID</label>
                {loading ? (
                  <div className='col-lg-8'>
                    <Skeleton />
                  </div>
                ) : (
                  <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fw-bold fs-6 me-2'>{payload?.university_id}</span>
                  </div>
                )}
              </div>
              <div className='row mb-2'>
                <label className='col-lg-4 fw-bold text-muted'>Phone</label>
                <div className='col-lg-8'>
                  {getComponent({
                    component: (
                      <>
                        <span className='fw-bold fs-6 text-dark text-hover-primary me-2'>
                          {payload?.phone_number}
                        </span>
                        <a
                          href={`tel:${payload?.phone_number}`}
                          className='badge badge-success text-hover-white'
                        >
                          Call Now
                        </a>
                      </>
                    ),
                  })}
                </div>
              </div>
              <div className='row mb-2'>
                <label className='col-lg-4 fw-bold text-muted'>Group</label>
                <div className='col-lg-8'>
                  {getComponent({
                    component: <span className='badge badge-info'>{payload?.section?.name}</span>,
                  })}
                </div>
              </div>
              <div className='row mb-2'>
                <label className='col-lg-4 fw-bold text-muted'>Supervisor</label>
                {loading ? (
                  <div className='col-lg-8'>
                    <Skeleton />
                  </div>
                ) : (
                  <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fw-bold fs-6 me-2'>
                      {payload?.supervisor?.user?.full_name}
                    </span>
                  </div>
                )}
              </div>
              <div className='row mb-2'>
                <label className='col-lg-4 fw-bold text-muted'>Reader</label>
                {loading ? (
                  <div className='col-lg-8'>
                    <Skeleton />
                  </div>
                ) : (
                  <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fw-bold fs-6 me-2'>
                      {payload?.supervisor?.reader?.user.full_name}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {profileInfo?.role === 'supervisor' && (
              <Link
                to={`/feedbacks/${drawerUserId}`}
                className='btn btn-primary'
                style={{borderRadius: 0}}
              >
                Give Feedback
              </Link>
            )}
          </div>
        </div>
        <div className='col-lg-6'>
          <DonutChart
            className='shadow-sm card-xl-stretch mb-5'
            chartColor='primary'
            chartHeight='200px'
            data={{
              numerator: payload?.attendance_count,
              denominator: payload?.supervisor?.attendance_count,
            }}
            cardHeight='400px'
          />
        </div>
        <div className='col-lg-12'>
          <div className='card shadow-sm mb-5'>
            <div className='card-header'>
              <h3 className='card-title fs-4 fw-bolder'>Project Overview</h3>
              {canEditOverview && (
                <div className='card-toolbar'>
                  {editing && (
                    <button onClick={() => setEditing(false)} className='btn btn-sm btn-light me-2'>
                      <KTSVG className='svg-icon' path='/media/icons/duotune/arrows/arr015.svg' />
                      Discard
                    </button>
                  )}
                  {editing && (
                    <button
                      disabled={formik.isSubmitting}
                      onClick={formik.submitForm}
                      className='btn btn-sm btn-primary'
                    >
                      <KTSVG className='svg-icon' path='/media/icons/duotune/arrows/arr016.svg' />
                      {formik.isSubmitting ? '...' : 'Save'}
                    </button>
                  )}
                  {!editing && (
                    <button onClick={() => setEditing(!editing)} className='btn btn-sm btn-primary'>
                      <KTSVG className='svg-icon' path='/media/icons/duotune/art/art003.svg' />
                      Edit
                    </button>
                  )}
                </div>
              )}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className='card-body'>
                <div /* className='row mb-4' */ className='row mb-4 align-items-center'>
                  <label className='col-lg-4 col-xl-2 fw-bold text-muted'>Title</label>
                  {loading ? (
                    <div className='col-lg-8'>
                      <Skeleton />
                    </div>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      {editing ? (
                        <input
                          {...formik.getFieldProps('name')}
                          className='form-control form-control-sm'
                        />
                      ) : (
                        // <span className='fw-bolder fs-6 me-2'>{values.title}</span>
                        <span className='fw-bolder fs-6 me-2'>{overviewValues?.name || '-'}</span>
                      )}
                    </div>
                  )}
                </div>
                <div className='row mb-4'>
                  <label className='col-lg-4 col-xl-2 fw-bold text-muted'>Language</label>
                  {loading ? (
                    <div className='col-lg-8'>
                      <Skeleton />
                    </div>
                  ) : (
                    <div className='col-lg-8 d-flex align-items-center'>
                      {editing ? (
                        // <CustomSelect
                        //   isMulti
                        //   styles={selectStyle}
                        //   options={programmingLanguages()}
                        //   onBlur={() => {}}
                        //   onChange={(val) => {
                        //     formik.setFieldValue('language', val)
                        //   }}
                        //   value={formik?.values?.language}
                        // />
                        <CustomCreatableSelect
                          isMulti
                          styles={selectStyle}
                          options={programmingLanguages()}
                          onBlur={() => {}}
                          onChange={(val: any) => {
                            formik.setFieldValue('language', val)
                          }}
                          value={formik?.values?.language}
                        />
                      ) : (
                        <>
                          {splitLanguagesForDropdown(languages)?.map((e, idx) => (
                            <span
                              key={idx}
                              className={`badge badge-light-${getRandomBootstrapColor()} mx-1`}
                            >
                              {e?.label}
                            </span>
                          ))}
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className='row-mb4'>
                  <label className='fw-bold text-muted'>Description</label>
                  <div className='d-flex align-items-center'>
                    {editing ? (
                      <WYSIWYG initialValue={overviewValues?.description || ''} formik={formik} />
                    ) : (
                      <>
                        {formik?.values && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formik?.values?.description || '',
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          {payload?.student_feedback && payload.student_feedback.length > 0 && (
            <LatestFeedbacks
              feedbacks={{
                // data: payload?.student_feedback?.splice(0, 2),
                data: payload?.student_feedback?.slice(0, 2),
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
