import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import StudentDetail from '../../../modules/students/StudentDetail'
import {MarksListWrapper} from '../../pages/supervisor-dashboard/user-management/users-list/MarksList'

const studentsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const StudentsMarksPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={studentsBreadCrumbs}>My Students' Marks</PageTitle>
            <MarksListWrapper type='admin' />
          </>
        }
      />
      {/* <Route
        path=':id'
        element={
          <>
            <PageTitle breadcrumbs={studentsBreadCrumbs}>Student Detail</PageTitle>
            <StudentDetail />
          </>
        }
      /> */}
      <Route index element={<Navigate to='/marks/list' />} />
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Route>
  </Routes>
)

export default StudentsMarksPage
