import clsx from 'clsx'
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {useDatastore} from '../../../../app/modules/auth/core/Data'
import {KTSVG} from '../../../helpers'
import {getNameInitials} from '../../../helpers/utils'
import {HeaderUserMenu, QuickLinks, Search} from '../../../partials'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {currentUser} = useAuth()
  const {profileInfo} = useDatastore()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <Search />
        {/* <div className='d-none d-md-flex align-items-center position-relative'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon svg-icon-2 position-absolute ms-4'
          />
          <input
            type='text'
            data-kt-inbox-listing-filter='search'
            className='form-control form-control-sm form-control-solid mw-100 min-w-150px min-w-md-300px ps-12'
            placeholder='Search here..'
          />
        </div> */}
      </div>

      {currentUser?.profile?.user?.user_type === 'admin' && (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
        </div>
      )}

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          style={
            currentUser?.profile?.user_image && {
              border: '3px solid #74c043',
              padding: '.25rem',
            }
          }
        >
          {profileInfo?.image ? (
            <img
              src={profileInfo?.image}
              style={{objectFit: 'cover', objectPosition: 'top'}}
              alt='asp'
            />
          ) : (
            <div className='symbol-label fs-4 fw-bold bg-danger text-inverse-danger'>
              {getNameInitials(profileInfo?.full_name)}
            </div>
          )}
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Topbar}
