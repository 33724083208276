import {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  loading: boolean
  text: string
  type?: 'button' | 'submit' | 'reset'
  className?: string
}

const AjaxButton: FC<Props> = ({className, loading, type = 'submit', text}) => {
  return (
    <button type={type} disabled={loading} className={className}>
      {!loading && (
        <>
          <KTSVG path='/media/icons/duotune/general/gen043.svg' className='svg-icon-1' />
          {text}
        </>
      )}
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          <span className='spinner-border spinner-border-sm align-middle me-2'></span>
          Please wait...
        </span>
      )}
    </button>
  )
}

export default AjaxButton
