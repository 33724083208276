import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react'
import {getAllSections, getAllSupervisors, getFeedbackSheet} from '../../../helpers/api'
import {ReaderProfileModel} from '../../../portals/readers/_models'
import {StudentProfileModel} from '../../../portals/students/components/_models'
import {SupervisorProfileModel} from '../../../portals/supervisors/_models'
import {RootUser} from '../../supervisors/user-management/users-list/core/_models'
import {useAuth} from './Auth'
import {Data, Feedback} from './_models'

type Group = {id: number; name: string}

type DatastoreContextProps = {
  supervisors: RootUser[]
  groups: Group[]
  expertise: string
  profileInfo?: Data
  feedbacks: Feedback[]
  setSupervisors: Dispatch<SetStateAction<RootUser[]>>
  setFeedbacks: Dispatch<SetStateAction<Feedback[]>>
  setGroups: Dispatch<SetStateAction<Group[]>>
  setExpertise: Dispatch<SetStateAction<string>>
  setProfileInfo: Dispatch<SetStateAction<Data>>
}

const initDatastoreContextProps = {
  supervisors: [],
  feedbacks: [],
  groups: [],
  expertise: '',
  profileInfo: undefined,
  setSupervisors: () => {},
  setFeedbacks: () => {},
  setExpertise: () => {},
  setGroups: () => {},
  setProfileInfo: () => {},
}

const AuthContext = createContext<DatastoreContextProps>(initDatastoreContextProps)

const DatastoreProvider: FC = ({children}) => {
  const {currentUser} = useAuth()
  const [supervisors, setSupervisors] = useState<RootUser[]>([])
  const [profileInfo, setProfileInfo] = useState<Data>({})
  const [expertise, setExpertise] = useState<string>('- No expertise mentioned')
  const [groups, setGroups] = useState<Group[]>([])
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([])

  // useEffect(() => {
  //   getAllSupervisors().then((value) => setSupervisors(value))
  //   getAllSections().then((value) => setGroups(value))
  // }, [])

  useEffect(() => {
    if (currentUser) {
      // console.log({currentUser})
      const role = currentUser.profile?.user?.user_type
      if (role === 'supervisor') {
        const supervisor = currentUser as SupervisorProfileModel
        setExpertise(supervisor.profile?.expertise!)
        getAllSections().then((value) => setGroups(value))
        getAllSupervisors().then((value) => setSupervisors(value))
        setProfileInfo({
          company_name: supervisor?.profile?.company_name,
          full_name: supervisor.full_name,
          image: supervisor.profile?.user_image,
          username: currentUser.username,
          current_position: supervisor.profile?.current_position,
          highest_degree: supervisor.profile?.highest_degree,
          university: supervisor.profile?.university,
          role,
        })
      } else if (role === 'reader') {
        const reader = currentUser as ReaderProfileModel
        setExpertise(reader.profile?.expertise!)
        getAllSupervisors().then((value) => setSupervisors(value))
        getAllSections().then((value) => setGroups(value))
        getFeedbackSheet().then((value) => setFeedbacks(value))
        setProfileInfo({
          company_name: reader?.profile?.company_name,
          full_name: reader.full_name,
          image: reader.profile?.user_image,
          username: currentUser.username,
          current_position: reader.profile?.current_position,
          highest_degree: reader.profile?.highest_degree,
          university: reader.profile?.university,
          role,
        })
      } else if (role === 'student') {
        const student = currentUser as StudentProfileModel
        getAllSections().then((value) => setGroups(value))
        getAllSupervisors().then((value) => setSupervisors(value))
        setProfileInfo({
          full_name: student.full_name,
          username: currentUser.username,
          image: student.profile?.user_image,
          role,
        })
      } else if (role === 'admin') {
        getAllSupervisors().then((value) => setSupervisors(value))
        getAllSections().then((value) => setGroups(value))
        getFeedbackSheet().then((value) => setFeedbacks(value))
        setProfileInfo({
          full_name: currentUser.full_name,
          image: currentUser.profile?.user_image,
          username: currentUser.username,
          phone_number: currentUser.profile?.phone_number,
          role,
        })
      }
    }
  }, [currentUser])

  return (
    <AuthContext.Provider
      value={{
        supervisors,
        setSupervisors,
        groups,
        setGroups,
        setExpertise,
        expertise,
        profileInfo,
        setProfileInfo,
        feedbacks,
        setFeedbacks,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useDatastore = () => {
  return useContext(AuthContext)
}

export {useDatastore, DatastoreProvider}
