/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
// import CountUp from 'react-countup'

import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  strokeColor?: string
  data?: {
    student_count?: number
    supervisor_count?: number
    reader_count?: number
    meetings_count?: number
  }
}

const MixedAdmin: React.FC<Props> = ({className, chartColor, chartHeight, data}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className='card-title fw-bolder text-white'>Overall Statistics</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <span
            className='btn btn-sm btn-icon btn-color-white btn-active-color- border-0 me-n3 pe-none'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </span>
          {/* end::Menu */}
        </div>
      </div>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
          style={{minHeight: chartHeight}}
        ></div>
        <div className='card-p mt-n20 position-relative'>
          <div className='row g-0'>
            <Link
              to='/students/list'
              className='col px-6 py-3 rounded-2 me-7 mb-7 hoverable'
              style={{color: '#5E6278', backgroundColor: '#FAFAFA'}}
            >
              <KTSVG
                path='/media/icons/custom/main/students.svg'
                className='svg-icon-2x svg-icon-warning d-block my-2'
              />
              <span className='fw-bold fs-1'>
                {/* <CountUp end={data?.student_count || 0} /> */}
                {data?.student_count || '0'}
              </span>
              <span className='d-block fw-bold fs-6 fs-xl-7'>Students</span>
            </Link>
            <Link
              to='/supervisors/list'
              className='col px-6 py-3 rounded-2 mb-7 hoverable'
              style={{color: '#5E6278', backgroundColor: '#FAFAFA'}}
            >
              <KTSVG
                path='/media/icons/custom/main/supervisor.svg'
                className='svg-icon-2x svg-icon-warning d-block my-2'
              />
              <span className='fw-bold fs-1'>
                {/* <CountUp end={data?.supervisor_count || 0} /> */}
                {data?.supervisor_count || '0'}
              </span>
              <span className='d-block fw-bold fs-6 fs-xl-7'>Supervisors</span>
            </Link>
          </div>
          <div className='row g-0'>
            <Link
              to='/readers/list'
              className='col px-6 py-3 rounded-2 me-7 hoverable'
              style={{color: '#5E6278', backgroundColor: '#FAFAFA'}}
            >
              <KTSVG
                path='/media/icons/custom/main/reader.svg'
                className='svg-icon-2x svg-icon-warning d-block my-2'
              />
              <span className='fw-bold fs-1'>
                {/* <CountUp end={data?.reader_count || 0} /> */}
                {data?.reader_count || '0'}
              </span>
              <span className='d-block fw-bold fs-6 fs-xl-7'>Readers</span>
            </Link>
            <div
              className='col px-6 py-3 rounded-2'
              style={{color: '#5E6278', backgroundColor: '#FAFAFA'}}
            >
              <KTSVG
                path='/media/icons/custom/main/meeting.svg'
                className='svg-icon-2x svg-icon-warning d-block my-2'
              />
              <span className='fw-bold fs-1'>
                {/* <CountUp end={data?.meetings_count || 0} /> */}
                {data?.meetings_count || '0'}
              </span>
              <span className='d-block fw-bold fs-6 fs-xl-7'>Total Meetings</span>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {MixedAdmin}
