import Select, {GroupBase, Props} from 'react-select'
import Creatable from 'react-select/creatable'

export function CustomSelect<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  return <Select {...props} theme={(theme) => ({...theme})} />
}

export function CustomCreatableSelect(props: any) {
  return <Creatable {...props} />
}
