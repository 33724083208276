import axios from 'axios'
import {AuthModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/profile/`
export const LOGIN_URL = `${API_URL}/token/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const FORGOT_PASSWORD_URL = `${API_URL}/forget-password/email/`
export const RESET_PASSWORD_URL = `${API_URL}/resetpassword/`

// Server should return AuthModel
export function login(email: string, password: string, remember?: boolean) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username: email,
    password,
    // remember,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export async function forgotPassword(email: string) {
  return (
    await axios.post(FORGOT_PASSWORD_URL, {
      email: email,
    })
  ).data
}

export async function resetPassword(email: string, password: string, token: string, key: string) {
  return (
    await axios.post(RESET_PASSWORD_URL, {
      email,
      password,
      token,
      key,
    })
  ).data
}
