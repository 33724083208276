import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideStudent() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        // icon='/media/icons/duotune/art/art002.svg'
        icon='/media/icons/duotune/abstract/abs029.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='text-white text-uppercase fs-8 ls-1'>Users</span>
        </div>
      </div> */}
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen055.svg'
        to='/feedbacks'
        title='Feedbacks'
      />
      <AsideMenuItem
        icon='/media/icons/duotune/coding/cod003.svg'
        to='/my-supervisor'
        title='My Supervisor'
      />
      <AsideMenuItem
        icon='/media/icons/duotune/maps/map001.svg'
        to='/my-reader'
        title='My Reader'
      />

      {/* <AsideMenuItemWithSub
        to='/feedbacks'
        title='Feedbacks'
        icon='/media/icons/duotune/communication/com012.svg'
        // icon='/media/icons/custom/sidebar/feedbacks.svg'
      > */}

      {/* </AsideMenuItemWithSub> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='text-white text-uppercase fs-8 ls-1'>Feedbacks</span>
        </div>
      </div> */}
      {/* <AsideMenuItem
        to='/feedbacks'
        title='Feedbacks'
        icon='/media/icons/custom/sidebar/feedbacks.svg'
      /> */}
    </>
  )
}
