import clsx from 'clsx'
import {useState} from 'react'
import styles from '../../styles/styles.module.css'

const ConfirmPasswordField = ({formik}: any) => {
  const [showPassword, setShowPassword] = useState(false)
  const inputType = showPassword ? 'text' : 'password'
  return (
    <div className='position-relative'>
      {/*begin::Input*/}
      <input
        {...formik.getFieldProps('confirmPassword')}
        type={inputType}
        className={clsx(
          'form-control',
          styles.form_control,

          formik.touched.confirmPassword && formik.errors.confirmPassword && styles.error_form,

          formik.touched.confirmPassword && !formik.errors.confirmPassword && styles.valid_form
        )}
      />
      {/*end::Input*/}
      {/*begin::CVV icon*/}
      <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
        {/*begin::Svg Icon | path: icons/duotune/finance/fin002.svg*/}
        <span
          onClick={() => setShowPassword(!showPassword)}
          className='svg-icon svg-icon-2 cursor-pointer'
        >
          {showPassword ? eye : eyeOff}
        </span>
        {/*end::Svg Icon*/}
      </div>
      {/*end::CVV icon*/}
    </div>
  )
}

const eye = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-5 w-5 stroke-gray-400'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    strokeWidth={2}
  >
    <path strokeLinecap='round' strokeLinejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
    />
  </svg>
)

const eyeOff = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-5 w-5 stroke-gray-400'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    strokeWidth={2}
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21'
    />
  </svg>
)

export default ConfirmPasswordField
