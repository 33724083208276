import React from 'react'
import {Link} from 'react-router-dom'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getNameInitials} from '../../../../_metronic/helpers/utils'
import {formatDate} from '../../../helpers/utils'

type Props = {
  className?: string
  feedbacks?: any[]
  currentUserFullName?: string
  currentUserImage?: string
}

const LatestFeedbacks: React.FC<Props> = ({
  className,
  feedbacks,
  currentUserFullName,
  currentUserImage,
}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header'>
        <h3 className='card-title fs-4 fw-bolder'>Latest Feedbacks</h3>
      </div>
      <div className='card-body pb-0'>
        {feedbacks?.slice(0, 2)?.map((e: any, idx: number) => (
          <div key={idx}>
            <div className='mb-2'>
              <div className='d-flex align-items-center flex-grow-1'>
                <div className='symbol symbol-45px me-5'>
                  {currentUserImage ? (
                    <img
                      // src={toAbsoluteUrl('/media/custom/biraj.png')}
                      src={currentUserImage}
                      alt=''
                      style={{objectFit: 'cover', objectPosition: 'top'}}
                    />
                  ) : (
                    <div className='symbol-label fs-4 fw-bold bg-danger text-inverse-danger'>
                      {getNameInitials(currentUserFullName)}
                    </div>
                  )}
                </div>
                <div className='d-flex flex-column'>
                  <Link to={'#'} className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                    {currentUserFullName}
                  </Link>
                  <span className='text-gray-400 fw-bold'>{formatDate(e?.created_at)}</span>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              {/* <p className='text-gray-800 fw-normal mb-5'>
                Outlines keep you honest. They stop you from indulging in poorly thought-out
                metaphors about driving and keep you focused on the overall structure of your post
              </p> */}
              <div
                className='text-gray-800 fw-normal mb-5'
                dangerouslySetInnerHTML={{__html: e?.feedback}}
              />
            </div>
            <div className='separator mb-4'></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {LatestFeedbacks}
