/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {getRandomBootstrapColor, splitLanguagesForDropdown} from '../../../_metronic/helpers/utils'

import {PageTitle} from '../../../_metronic/layout/core'

import DashboardStatItem from '../../components/reusable-blocks/DashboardStatItem'
import {useAuth} from '../../modules/auth'
import {getUserByToken} from '../../modules/auth/core/_requests'

import {DonutChart} from '../../modules/students/components/DonutChart'
import {LatestFeedbacks} from '../../modules/students/components/LatestFeedbacks'
import {StudentProfileModel} from './components/_models'

const DashboardPage: FC = () => {
  const {currentUser, auth} = useAuth()
  const [userData, setUserData] = useState<StudentProfileModel | undefined>(undefined)

  useEffect(() => {
    if (auth) {
      getUserByToken(auth.access).then((res) => setUserData(res?.data))
    }
  }, [auth])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <DashboardStatItem
          to='/my-supervisor'
          icon='/media/icons/duotune/communication/com006.svg'
          title={currentUser?.profile?.supervisor?.user?.full_name || '-'}
          subtitle='Supervisor'
          titleStyles='text-hover-primary'
        />
        <DashboardStatItem
          to='/my-reader'
          icon='/media/icons/duotune/general/gen028.svg'
          title={currentUser?.profile?.supervisor?.reader?.user?.full_name || '-'}
          subtitle='Reader'
          titleStyles='text-hover-primary'
        />
        <DashboardStatItem
          to='#'
          icon='/media/icons/duotune/general/gen013.svg'
          title={currentUser?.profile?.attendance_count?.toString()!}
          subtitle='Attended Meetings'
        />
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <div className='col-12 mt-5 mt-md-0'>
            <div className='card mb-5'>
              <div className='card-header'>
                <h3 className='card-title fs-4 fw-bolder'>Project Overview</h3>
              </div>
              <div className='card-body' style={{wordBreak: 'break-all'}}>
                <div className='row mb-4'>
                  <label className='fw-bold text-muted'>Title</label>
                  <div className='d-flex align-items-center'>
                    <span className='fw-bolder fs-6 me-2'>
                      {(currentUser?.profile?.student_project &&
                        currentUser?.profile?.student_project?.length > 0 &&
                        currentUser?.profile?.student_project![0].name) ||
                        '-'}
                    </span>
                  </div>
                </div>
                <div className='row mb-4'>
                  <label className='fw-bold text-muted'>Language</label>
                  <div className='d-flex align-items-center'>
                    {(currentUser?.profile?.student_project &&
                      currentUser?.profile?.student_project?.length > 0 &&
                      splitLanguagesForDropdown(
                        currentUser?.profile?.student_project![0].language || ''
                      )?.map((e, idx) => (
                        <span
                          key={e.label}
                          className={clsx(`badge badge-light-${getRandomBootstrapColor()}`, {
                            'mx-1': idx !== 0,
                          })}
                        >
                          {e.label}
                        </span>
                      ))) ||
                      '-'}
                  </div>
                </div>
                <div className='row-mb4'>
                  <label className='fw-bold text-muted'>Description</label>
                  <div className='d-flex align-items-center'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          (currentUser?.profile?.student_project &&
                            currentUser?.profile?.student_project?.length > 0 &&
                            currentUser?.profile?.student_project![0].description!) ||
                          '-',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DonutChart
            className='mb-5'
            chartColor='primary'
            chartHeight='200px'
            data={{
              denominator: currentUser?.profile?.supervisor?.attendance_count,
              numerator: currentUser?.profile?.attendance_count,
            }}
          />
        </div>
        <div className='col-xl-8'>
          {/* {currentUser?.profile?.student_feedback &&
            currentUser.profile.student_feedback?.length > 0 && (
              <LatestFeedbacks
                feedbacks={{
                  data: currentUser.profile.student_feedback.splice(0, 3),
                  supervisor: {
                    image: currentUser?.profile?.supervisor?.user_image,
                    name: currentUser?.profile?.supervisor?.user?.full_name,
                  },
                }}
              />
            )} */}
          {userData?.profile?.student_feedback && userData?.profile?.student_feedback?.length > 0 && (
            <LatestFeedbacks
              feedbacks={{
                data: userData?.profile.student_feedback.splice(0, 3),
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
