/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {formatDate} from '../../../helpers/utils'
import {getNameInitials} from '../../../../_metronic/helpers/utils'
import {StudentFeedback} from '../user-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'

// type Props = {
//   className?: string
//   feedbacks?: {
//     data?: StudentFeedback[]
//     supervisor?: {image?: string; name?: string}
//   }
// }

type Props = {
  className?: string
  feedbacks?: {
    data?: StudentFeedback[]
  }
}

const LatestFeedbacks: React.FC<Props> = ({className, feedbacks}) => {
  const {currentUser} = useAuth()
  return (
    <div className={`card ${className}`}>
      <div className='card-header'>
        <h3 className='card-title fs-4 fw-bolder'>Latest Feedbacks</h3>
        {/* <div className='card-toolbar'>
          <a href='/'>Generate Feedback</a>
        </div> */}
      </div>
      <div className='card-body pb-0'>
        {feedbacks?.data?.map((e, idx) => (
          <div key={idx}>
            <div className='mb-5'>
              <div className='d-flex align-items-center flex-grow-1'>
                <div className='symbol symbol-45px me-5'>
                  {/* {feedbacks?.supervisor?.image ? (
                    <img
                      src={feedbacks.supervisor?.image}
                      alt='supervisor'
                      style={{objectFit: 'cover', objectPosition: 'top'}}
                    />
                  ) : (
                    <div className='symbol-label fs-4 fw-bold bg-danger text-inverse-danger'>
                      {getNameInitials(feedbacks?.supervisor?.name)}
                    </div>
                  )} */}
                  {e?.supervisor?.id ? (
                    e?.supervisor?.user_image ? (
                      <img
                        src={e?.supervisor?.user_image}
                        alt='supervisor'
                        style={{objectFit: 'cover', objectPosition: 'top'}}
                      />
                    ) : (
                      <div className='symbol-label fs-4 fw-bold bg-danger text-inverse-danger'>
                        {getNameInitials(e?.supervisor?.user?.full_name)}
                      </div>
                    )
                  ) : e?.reader?.user_image ? (
                    <img
                      src={e?.reader?.user_image}
                      alt='reader'
                      style={{objectFit: 'cover', objectPosition: 'top'}}
                    />
                  ) : (
                    <div className='symbol-label fs-4 fw-bold bg-danger text-inverse-danger'>
                      {getNameInitials(e?.reader?.user?.full_name)}
                    </div>
                  )}
                </div>
                <div className='d-flex flex-column'>
                  <Link
                    // to={
                    //   e?.supervisor?.id
                    //     ? '/my-supervisor'
                    //     : '/my-reader'
                    // }

                    to={
                      currentUser?.profile?.user?.user_type === 'admin'
                        ? e?.supervisor?.id
                          ? `/supervisors/${e?.supervisor?.id}`
                          : `/readers/${e?.reader?.id}`
                        : currentUser?.profile?.user?.user_type === 'supervisor'
                        ? e?.supervisor?.id
                          ? `/profile/overview`
                          : `/reader/${e?.reader?.id}`
                        : currentUser?.profile?.user?.user_type === 'reader'
                        ? e?.supervisor?.id
                          ? `/supervisors/${e?.supervisor?.id}`
                          : `/profile/overview`
                        : e?.supervisor?.id
                        ? `/my-supervisor`
                        : `/my-reader`
                    }
                    className='text-gray-800 text-hover-primary fs-6 fw-bolder'
                  >
                    {/* {feedbacks.supervisor?.name} */}
                    {e?.supervisor?.id
                      ? e?.supervisor?.user?.full_name
                      : e?.reader?.user?.full_name}
                  </Link>
                  <span className='text-gray-400 fw-bold'>{formatDate(e.created_at)}</span>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <div
                className='text-gray-800 fw-normal mb-5'
                dangerouslySetInnerHTML={{__html: e.feedback}}
              />
            </div>
            {feedbacks &&
              feedbacks.data &&
              feedbacks.data.length &&
              idx !== feedbacks?.data?.length - 1 && <div className='separator mb-4'></div>}
          </div>
        ))}
      </div>
    </div>
  )
}

export {LatestFeedbacks}
