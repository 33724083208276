/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className?: string
  chartColor: string
  chartHeight: string
  data?: {
    numerator?: number
    denominator?: number
  }
  cardHeight?: string
}

const DonutChart: React.FC<Props> = ({className, chartColor, chartHeight, data, cardHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  let percent: number
  percent = 0
  if (data?.denominator) {
    percent = parseFloat((((data?.numerator ?? 1) / (data?.denominator ?? 1)) * 100).toFixed(0))
  }

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, percent))

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, percent])

  return (
    <div className={`card ${className}`} style={cardHeight ? {height: cardHeight} : {}}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-4 mb-1'>Attendance</span>
          <span className='text-muted fw-bold fs-7'>Attended meetings: {data?.numerator}</span>
          <span className='text-muted fw-bold fs-7'>Total meetings: {data?.denominator}</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <span className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </span>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1 mb-4'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>
        <div className='d-flex flex-column content-justify-center flex-grow-1'>
          <div className='d-flex fs-6 fw-bold align-items-center my-1'>
            <div className='bullet w-8px h-6px rounded-2 bg-primary me-3'></div>
            <div className='fs-6 fw-bold text-gray-400 flex-shrink-0'>Attended</div>
            <div className='separator separator-dashed min-w-10px flex-grow-1 mx-2'></div>
            <div className='ms-auto fw-boldest text-gray-700 text-end'>{percent ?? '-'}%</div>
          </div>
          <div className='d-flex fs-6 fw-bold align-items-center'>
            <div
              className='bullet w-8px h-6px rounded-2 me-3'
              style={{backgroundColor: '#E4E6EF'}}
            ></div>
            <div className='fs-6 fw-bold text-gray-400 flex-shrink-0'>Not Attended</div>
            <div className='separator separator-dashed min-w-10px flex-grow-1 mx-2'></div>
            <div className='ms-auto fw-boldest text-gray-700 text-end'>{100 - percent}%</div>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, percent: number): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [percent],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {DonutChart}
