/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {MixedAdmin} from '../../../_metronic/partials/widgets'
import {getAllSections, getAllSupervisors} from '../../helpers/api'
import {useAuth} from '../../modules/auth'
import {useDatastore} from '../../modules/auth/core/Data'
import {BarChart} from '../../modules/supervisors/components/BarChart'
import {AdminProfileModel} from '../../portals/fyp-coordinators/_models'
// import MeetingThisWeek from './components/MeetingThisWeek'

const DashboardPage: FC = () => {
  const {setSupervisors, setGroups} = useDatastore()
  const {currentUser} = useAuth()
  const currentAdmin = currentUser as AdminProfileModel

  useEffect(() => {
    getAllSupervisors().then((value) => setSupervisors(value))
    getAllSections().then((value) => setGroups(value))
    // eslint-disable-next-line
  }, [])

  const getWeeksRange = (start?: Date, end?: Date) => {
    let sDate
    let eDate
    let dateArr = []
    if (start && end) {
      while (start <= end) {
        if (start.getDay() === 0 || (dateArr.length === 0 && !sDate)) {
          sDate = new Date(start.getTime())
        }

        if ((sDate && start.getDay() === 6) || start.getTime() === end.getTime()) {
          eDate = new Date(start.getTime())
        }

        if (sDate && eDate) {
          dateArr.push({startDate: sDate, endDate: eDate})
          sDate = undefined
          eDate = undefined
        }

        start.setDate(start.getDate() + 1)
      }
      return dateArr
    }
  }

  const getChartData = (date?: Date[], startDate?: Date, endDate?: Date) => {
    const weeksRange = getWeeksRange(startDate, endDate)
    let chartData: any[] = []
    if (date?.length && startDate && endDate) {
      date?.forEach((d) =>
        // getWeeksRange(new Date('2022-12-01'), new Date('2022-12-20'))?.forEach(
        //   (dateRange, idx) => {
        //     if (d >= dateRange?.startDate && d <= dateRange?.endDate) {
        //       chartData.push(idx + 1)
        //     }
        //   }
        // )
        weeksRange?.forEach((dateRange, idx) => {
          if (d >= dateRange?.startDate && d <= dateRange?.endDate) {
            chartData.push(idx + 1)
          }
        })
      )
      // Displays: [4, 4, 4, 2, 1, 1] i.e. which data lies on which week.
      // console.log(chartData)

      let counts: any = {}
      // Count Repeated array elements
      chartData?.forEach((cd) => {
        counts[cd] = (counts[cd] || 0) + 1
      })
      // Displays: Ex-{1: 2, 2: 1, 4: 3} i.e. how many data are there in which week. Here, 2 data is in 1st week, 1 in 2nd week and 3 in 4th week.
      // console.log(counts)

      // Displays data in reverse order for barchart. The above data is: [3,1,2] i.e Data in 4th, 2nd, 1st week.
      return Object.keys(counts)
        ?.reverse()
        ?.map((k) => counts[k])
        ?.slice(0, 7)
    } else return undefined
  }

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <MixedAdmin
            className='card-xl-stretch mb-xl-8 mb-5'
            chartColor='primary'
            chartHeight='175px'
            data={{
              student_count: currentUser?.profile?.student_count,
              supervisor_count: currentUser?.profile?.supervisor_count,
              meetings_count: currentUser?.profile?.meetings_count,
              reader_count: currentUser?.profile?.reader_count,
            }}
          />
        </div>
        <div className='col-xl-8 mb-xl-8 mb-5'>
          {/* Example */}
          {/* <>
            {getChartData(
              [
                new Date('2022-12-01'),
                new Date('2022-12-04'),
                new Date('2022-12-04'),
                new Date('2022-12-06'),
                new Date('2022-12-19'),
              ],
              new Date('2022-12-01'),
              new Date('2022-12-19')
            )}
          </> */}
          {currentAdmin?.profile?.meeting_log && (
            <BarChart
              chartData={getChartData(
                currentAdmin?.profile?.meeting_log?.map((d) => new Date(d?.date)),
                currentAdmin?.profile?.meeting_log
                  ? new Date(
                      currentAdmin?.profile?.meeting_log[
                        currentAdmin?.profile?.meeting_log?.length - 1
                      ]?.date
                    )
                  : undefined,
                currentAdmin?.profile?.meeting_log
                  ? new Date(currentAdmin?.profile?.meeting_log[0]?.date)
                  : undefined
              )}
            />
          )}
          {/* {currentUser?.profile?.meeting_log && (
            <BarChart
              chartData={currentUser?.profile?.meeting_log?.slice(0, 7)?.map((e: any) => e[1])}
            />
          )} */}
        </div>
        <div className='mt-0 col-xl-4 mb-5'>
          <div className='card mb-5 mb-md-0'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Unattentive Students</span>
                <span className='text-muted fw-bold fs-7'>Students with least attendance</span>
              </h3>
            </div>
            <div className='card-body'>
              {currentAdmin.profile?.least_attentive_students
                ?.filter(
                  (e) =>
                    e.supervisor_attendance_count !== 0 && e.supervisor_attendance_count !== null
                )
                ?.map((e, idx) => {
                  // const percent = (
                  //   ((e?.attendance_count || 1) / (e?.supervisor_attendance_count || 1)) *
                  //   100
                  // ).toFixed(0)
                  const percent = (
                    ((e?.attendance_count || 0) / (e?.supervisor_attendance_count || 1)) *
                    100
                  ).toFixed(0)
                  return (
                    <div key={idx} className='row mb-4'>
                      <div className='col-6'>
                        <Link
                          to={`/students/${e?.id}`}
                          className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                        >
                          {e.user?.full_name}
                        </Link>
                        <span className='text-muted fw-bold d-block fs-7'>{e.university_id}</span>
                      </div>
                      <div className='col-6 d-flex align-items-center'>
                        <div className='progress h-6px w-100'>
                          <div
                            className='progress-bar bg-danger'
                            role='progressbar'
                            style={{width: `${percent}%`}}
                          />
                        </div>
                        <div className='d-flex flex-stack'>
                          <span className='text-muted ms-2 fs-7 fw-bold'>{percent}%</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        {/* <div className='mt-0 col-xl-8'>
          <MeetingThisWeek />
        </div> */}
      </div>
      {/* <div className='row gy-5 g-xl-8 mb-5 mb-xl-10'></div> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
