import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
// import {AsideMenuItem} from '../AsideMenuItem'
import {AsideCollapsedMenuItem} from './AsideCollapsedMenuItem'

export function AsideCollapsedSupervisor() {
  const intl = useIntl()

  return (
    <>
      <AsideCollapsedMenuItem
        to='/dashboard'
        // icon='/media/icons/duotune/art/art002.svg'
        icon='/media/icons/duotune/abstract/abs029.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideCollapsedMenuItem
        to='/students/list'
        title='Students'
        icon='/media/icons/duotune/communication/com006.svg'
        // icon='/media/icons/custom/sidebar/supervisor.svg'
      />
      <AsideCollapsedMenuItem
        icon='/media/icons/duotune/graphs/gra005.svg'
        to='/marks/list'
        title="Student's Marks"
      />
      <AsideCollapsedMenuItem
        icon='/media/icons/duotune/general/gen055.svg'
        to='/feedbacks'
        title='Feedbacks'
      />
    </>
  )
}
