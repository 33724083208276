/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {useQuill} from 'react-quilljs'
// import { KTSVG } from '../../../../_metronic/helpers'

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],

    [{list: 'ordered'}, {list: 'bullet'}],
    [{indent: '-1'}, {indent: '+1'}],

    [{header: [1, 2, 3, 4, 5, 6, false]}],
    ['link'],
    [{color: []}, {background: []}],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
}

const WYSIWYG: FC<{
  formik?: any
  initialValue?: string
  setQuill?: any
  clearText?: {value: boolean; setClearText: any}
}> = ({formik, initialValue, setQuill, clearText}) => {
  const {quill, quillRef} = useQuill({modules})

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(initialValue || '')
    }
  }, [quill, initialValue])

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        formik.setFieldValue('Description', quillRef.current.firstChild.innerHTML)
        // formik.setFieldValue('plainText', quill.getText())
      })
      if (setQuill) {
        setQuill(quill)
      }
    }
  }, [quill])

  useEffect(() => {
    if (clearText?.value) {
      quill.clipboard.dangerouslyPasteHTML('')
      clearText.setClearText(false)
    }
  }, [clearText])

  return (
    <div style={{width: '100%'}}>
      <div ref={quillRef} />
      {/* <button
        disabled={formik.isSubmitting}
        className='btn btn-primary btn-sm mt-5'
        onClick={() => formik.handleSubmit()}
      >
        {!formik.isSubmitting && (
          <>
            <KTSVG className='svg-icon svg-icon-2' path='/media/icons/duotune/general/gen043.svg' />
            Done
          </>
        )}
        {formik.isSubmitting && (
          <span className='indicator-progress' style={{display: 'block'}}>
            <span className='spinner-border spinner-border-sm align-middle me-2'></span>
            Please wait...
          </span>
        )}
      </button> */}
    </div>
  )
}

export default WYSIWYG
