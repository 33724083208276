import {KTSVG} from '../../../_metronic/helpers'
import {BarChart} from './components/BarChart'
import {LatestFeedbacks} from './components/LatestFeedbacks'
// import CountUp from 'react-countup'

import styles from '../portal/common/styles/styles.module.css'
import {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {getUserById} from './user-management/users-list/core/_requests'
import {RootUser} from './user-management/users-list/core/_models'
import {createErrorDialog} from '../../helpers/confirmation-dialog'
import clsx from 'clsx'
import {getNameInitials} from '../../../_metronic/helpers/utils'

const SupervisorDetail = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [payload, setPayload] = useState<RootUser>()

  useEffect(() => {
    if (id) {
      getUserById(id)
        .then((res) => setPayload(res))
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              createErrorDialog('No such data found')
              navigate('/supervisors/list')
            }
          }
        })
    }
  }, [id, navigate])

  let avatar: any

  if (payload?.user_image) {
    avatar = (
      <img
        src={payload?.user_image}
        alt='student'
        className='h-125px mb-4'
        style={{objectFit: 'contain'}}
      />
    )
  } else {
    avatar = (
      <div className='symbol symbol-70px symbol-lg-125px symbol-fixed symbol-circle overflow-hidden me-3 mb-2'>
        <div className={clsx('symbol-label fs-1', `bg-light-danger`, `text-danger`)}>
          {getNameInitials(payload?.user.full_name)}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='card shadow-sm mb-5'>
        <div className='row border-between gx-10 card-body'>
          <div className='col-lg-6'>
            <div className={`d-flex pe-4 ${styles.responsive_divider}`}>
              <div className='me-7 mb-4'>{avatar}</div>
              <div>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-2 fw-bolder me-1'>
                    {payload?.user.full_name}
                  </span>
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-1 svg-icon-primary'
                  />
                </div>
                <div className='fw-bold fs-6 mb-4 pe-2'>
                  {payload?.current_position && (
                    <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com009.svg'
                        className='svg-icon-4 me-1'
                      />
                      {payload?.current_position} at {payload?.company_name}
                    </span>
                  )}
                  {payload?.highest_degree && (
                    <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/art/art008.svg'
                        className='svg-icon-4 me-1'
                      />
                      {payload?.highest_degree}
                    </span>
                  )}
                  {payload?.university && (
                    <span className='d-flex align-items-center text-gray-400 mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/maps/map001.svg'
                        className='svg-icon-4 me-1'
                      />
                      {payload?.university}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6 col-sm-3 col-md-4'>
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr066.svg'
                      className='svg-icon-3 svg-icon-success me-2'
                    />
                    <div className='fs-2 fw-bolder'>
                      {/* <CountUp end={payload?.attendance_count || 0} /> */}
                      {payload?.attendance_count || '0'}
                    </div>
                  </div>

                  <div className='fw-bold fs-6 text-gray-400'>Meetings</div>
                </div>
              </div>
              <Link to='/students/list' className='col-6 col-sm-3 col-md-4'>
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <div className='d-flex align-items-center'>
                    <KTSVG
                      className='svg-icon-3 text-primary me-2'
                      path='/media/icons/duotune/communication/com006.svg'
                    />
                    <div className='fs-2 text-dark fw-bolder'>
                      {/* {(payload?.students_count && <CountUp end={payload.students_count} />) || '0'} */}
                      {payload?.students_count || '0'}
                    </div>
                  </div>

                  <div className='fw-bold fs-6 text-gray-400'>Students</div>
                </div>
              </Link>
            </div>
          </div>
          <div className='col-lg-6 mt-5 mt-lg-0'>
            <div className='text-gray-800 fs-2 fw-bolder'>Expertise</div>
            <div
              className={styles.expertise}
              dangerouslySetInnerHTML={{__html: payload?.expertise}}
            />
          </div>
        </div>
      </div>
      <div className='row gx-10'>
        <div className='col-md-6'>
          <div className='card shadow-sm mb-5 mb-xl-10'>
            <div className='card-body'>
              <div className='hover-scroll-overlay-y position-relative' style={{height: '644px'}}>
                <table className='table align-middle table-row-bordered gy-4'>
                  <thead
                    className='border-gray-200 fs-5 fw-bold sticky-top bg-white'
                    style={{zIndex: 1}}
                  >
                    <tr>
                      <th className='ps-4 w-125px'>Student Id</th>
                      <th>Student Name</th>
                      <th>Attendance</th>
                    </tr>
                  </thead>
                  <tbody className='fw-6 fw-bold text-gray-600'>
                    {payload?.students?.map((e) => (
                      <tr key={e.id}>
                        <td className='ps-4'>
                          <span className='badge badge-light-success fs-7 fw-bolder'>
                            {e.university_id}
                          </span>
                        </td>
                        <td>
                          <Link className='text-muted text-hover-primary' to={`/students/${e.id}`}>
                            {e.user.full_name}
                          </Link>
                        </td>
                        <td>
                          <span className='badge badge-light-danger fs-7 fw-bolder'>
                            {e?.attendance_count}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='card shadow-sm mb-5'>
            <div className='card-header'>
              <h3 className='card-title fs-4 fw-bolder'>Supervisor Details</h3>
            </div>
            <div className='card-body'>
              <div className='row mb-4'>
                <label className='col-lg-5 fw-bold text-muted'>Supervisor Code</label>
                <div className='col-lg-7 d-flex align-items-center'>
                  <span className='fw-bold text-primary fs-6 me-2'>{payload?.code}</span>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-5 fw-bold text-muted'>Classroom Code</label>
                <div className='col-lg-7 d-flex align-items-center'>
                  <span className='fw-bold text-primary fs-6 me-2'>{payload?.classroom_code}</span>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-5 fw-bold text-muted'>Supervisor Email</label>
                <div className='fw-bold col-lg-7 d-flex text-break align-items-center text-primary text-decoration-underline'>
                  <a href={`mailto:${payload?.user.username}`}>{payload?.user.username}</a>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-5 fw-bold text-muted'>Assigned Reader</label>
                <div className='fw-bold col-lg-7 text-break d-flex align-items-center text-primary text-decoration-underline'>
                  <Link to={`/readers/${payload?.reader?.id}`}>
                    {payload?.reader?.user.full_name}
                  </Link>
                </div>
              </div>
              {payload?.linkedin_link && (
                <div className='row mb-4 align-items-center'>
                  <label className='col-lg-5 fw-bold text-muted'>Visit Profile</label>
                  <div className='col-lg-7 d-flex'>
                    <a href={payload?.linkedin_link} target='_blank' rel='noreferrer'>
                      <img src='/media/icons/custom/social/linkedin-badge.svg' alt='linkedin' />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          {payload?.feedbacks && payload?.feedbacks.length > 0 && (
            <LatestFeedbacks
              className='mb-5 shadow-sm'
              feedbacks={payload?.feedbacks}
              currentUserFullName={payload?.user?.full_name}
              currentUserImage={payload?.user_image}
            />
          )}
        </div>
      </div>
      {/* {payload?.meeting_log && (
        <BarChart chartData={payload?.meeting_log?.slice(0, 7)?.map((e: any) => e[1])} />
      )} */}
      {payload?.meeting_log && (
        <BarChart
          chartData={Object.values(payload?.meeting_log)?.slice(0, 7)}
          xAxis={Object.values(payload?.meeting_log)
            ?.map((it, idx) => `Week ${idx + 1}`)
            ?.reverse()}
        />
      )}
    </>
  )
}

export default SupervisorDetail
