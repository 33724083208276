import clsx from 'clsx'
import {FC, useEffect, useRef, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {createErrorDialog} from '../../../../helpers/confirmation-dialog'

const ImageUploader: FC<{formik: any; className?: string}> = ({formik, className}) => {
  const [source, setSource] = useState<string>()
  const [loading, setLoading] = useState(false)
  const fileRef = useRef<any>()

  useEffect(() => {
    if (formik.values.Image) {
      setSource(formik.values.Image)
    }
  }, [formik])

  const handleSource = ({target}: {target: HTMLInputElement}) => {
    let files = target.files

    setLoading(true)
    let reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        setSource(reader.result as string)
        setTimeout(() => setLoading(false), 2000)
      },
      false
    )
    if (files && files.length > 0) {
      if (
        files[0].type === 'image/jpeg' ||
        files[0].type === 'image/png' ||
        files[0].type === 'image/jpg'
      ) {
        reader.readAsDataURL(files[0])
        if (formik) formik.setFieldValue('Image', files[0])
      } else {
        setLoading(false)
        createErrorDialog('Please make sure that the file is a <b>jpg/png/jpeg</b> image.')
      }
    }
    fileRef.current!.value = ''
  }

  const handleClear = () => {
    setSource(undefined)
    setLoading(false)
    formik.setFieldValue('Image', '')
  }

  const backgroundImage = source ? `url(${source})` : 'none'
  return (
    <div className={clsx('d-flex blockui mb-9', className)}>
      <div
        className={clsx(
          'image-input',
          'image-input-outline',
          'image-input-circle',
          !source && 'image-input-empty'
        )}
        data-kt-image-input='true'
        style={{
          backgroundImage: 'url("/media/svg/avatars/blank.svg")',
        }}
      >
        {loading && (
          <div className='blockui-overlay' style={{zIndex: 1, borderRadius: '50%'}}>
            <span className='spinner-border text-primary' />
          </div>
        )}
        <div className='image-input-wrapper w-125px h-125px' style={{backgroundImage}}></div>
        <label
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title=''
          data-bs-original-title='Change avatar'
        >
          <OverlayTrigger placement='right' overlay={<Tooltip>Change Avatar</Tooltip>}>
            <i className='bi bi-pencil-fill fs-7'></i>
          </OverlayTrigger>
          <input
            ref={fileRef}
            type='file'
            name='avatar'
            accept='.png, .jpg, .jpeg'
            onChange={handleSource}
          />
          <input type='hidden' name='avatar_remove' />
        </label>
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='cancel'
          data-bs-toggle='tooltip'
          title=''
          data-bs-original-title='Cancel avatar'
        >
          <i className='bi bi-x fs-2'></i>
        </span>
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='remove'
          data-bs-toggle='tooltip'
          title=''
          data-bs-original-title='Remove avatar'
          onClick={handleClear}
        >
          <i className='bi bi-x fs-2 text-hover-danger'></i>
        </span>
      </div>
    </div>
  )
}

export default ImageUploader
