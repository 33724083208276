import {ListViewProvider} from './core/ListViewProvider'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import {MarksTable} from './table/MarksTable'
import {useEffect, useState} from 'react'
import {getFeedbackSheet} from '../../../../helpers/api'
import {Feedback} from '../../../../modules/auth'
import {useDebounce} from '../../../../hooks/useDebounce'
import {QueryRequestProvider} from './core/MarksQueryReqProvider'
import {UsersListFilter} from './components/header/UsersListFilter'

interface Props {
  type?: 'supervisor' | 'reader' | 'admin'
}

const MarksList = ({type = 'supervisor'}: Props) => {
  const [totalCount, setTotalCount] = useState<number>(0)
  const [feedbackSheets, setFeedbackSheets] = useState<Feedback[]>()
  const [sheet, setSheet] = useState<string | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
  const [filterBySupervisorId, setFilterBySupervisorId] = useState<number>()
  // Debounce for calling search api
  const debouncedSearchValue = useDebounce(searchQuery ?? '', 500)

  const getSheets = async () => {
    try {
      const feedbackSheets = await getFeedbackSheet()
      if (feedbackSheets) {
        setFeedbackSheets(feedbackSheets)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getSheets()
  }, [])

  useEffect(() => {
    if (feedbackSheets?.length) setSheet(feedbackSheets[0]?.id?.toString())
  }, [feedbackSheets])

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title d-flex align-items-end'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Students</span>
              <span className='text-muted mt-1 fw-bold fs-7'>{totalCount} students in total</span>
            </h3>
          </div>
          <div className='d-flex'>
            <div className='card-toolbar' style={{width: 275}}>
              <div className='d-flex justify-content-end w-100' data-kt-user-table-toolbar='base'>
                <div className='position-relative d-flex align-items-center me-4 w-100'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-2'
                  />
                  <input
                    // ref={searchRef}
                    type='search'
                    className='form-control py-3 ps-10'
                    placeholder='Search names...'
                    name='search'
                    // disabled={isLoading}
                    onChange={(e) => {
                      setSearchQuery(e?.target?.value)
                    }}
                  />
                </div>
                {/* <UsersListFilter></UsersListFilter> */}
              </div>
              {/* <button
              // disabled={isLoading}
              type='button'
              className='btn btn-light-primary me-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              Filter: {feedbackSheets?.find((f) => f?.id?.toString() === sheet)?.name}
            </button> */}
              {/* end::Filter Button */}
              {/* begin::SubMenu */}
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
              >
                {/* begin::Header */}
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='d-sm-flex align-items-center mb-5'>
                    <label className='form-label fs-6 fw-bold me-4'>Sheet:</label>
                    <select
                      onChange={({target}) => {
                        // setSection(target.value)
                        setSheet(target?.value)
                      }}
                      className='form-select'
                      // value={sheet}
                      // defaultValue=''
                    >
                      <option value='' disabled hidden>
                        Select Section
                      </option>
                      {feedbackSheets?.map((e, idx) => (
                        <option key={idx} value={e?.id} selected={idx === 0}>
                          {e?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    // onClick={resetData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                  >
                    Reset
                  </button>
                  <button
                    type='button'
                    // onClick={filterData}
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                  >
                    Apply
                  </button>
                </div> */}
                </div>
              </div>
            </div>
            {type === 'admin' && (
              <div className='card-toolbar'>
                <UsersListFilter setFilterBySupervisorId={setFilterBySupervisorId} />
              </div>
            )}
          </div>
        </div>
        <MarksTable
          sheetQuery={sheet ?? ''}
          searchQuery={debouncedSearchValue ?? ''}
          setTotalCount={setTotalCount}
          type={type}
          filterBySupervisorId={filterBySupervisorId}
        />
      </KTCard>
    </>
  )
}

const MarksListWrapper = ({type = 'supervisor'}: Props) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <MarksList type={type} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export {MarksListWrapper}
