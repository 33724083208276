import moment from 'moment'

export const formatDate = (date: string) => {
  return moment(new Date(date)).calendar()
}

export const getTodaysDate = () => {
  const dateObj = new Date()
  const month = dateObj.getUTCMonth() + 1 //months from 1-12
  const day = dateObj.getUTCDate()
  const year = dateObj.getUTCFullYear()

  return year + '_' + month + '_' + day
}

export const formatDateFromNow = (date?: string) => {
  if (date) {
    return moment(new Date(date)).fromNow()
  }
  return 'Not active yet'
}

export const determineGrade = (marks: number = 0) => {
  let grade
  if (marks < 40) grade = 'F'
  else if (marks < 43) grade = 'E'
  else if (marks < 50) grade = 'D'
  else if (marks < 60) grade = 'C'
  else if (marks < 70) grade = 'B'
  else grade = 'A'
  return grade
}
