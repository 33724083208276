import * as Yup from 'yup'

export const email = Yup.string()
  .email('Wrong email format')
  .min(3, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required()

export const password = Yup.string()
  .min(6, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required()

export const confirmPassword = Yup.string()
  .min(6, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('confirm password is a required field')
  .oneOf([Yup.ref('password')], 'Passwords do not match.')

export const number = Yup.number().typeError('Only numbers are allowed').required()

// export const phone = Yup.string()
//   .matches(/^98[0-9]{8}$/, 'Phone number is not valid')
//   .required()

export const phone = Yup.string()
  .matches(/^9[0-9]{9}$/, 'Phone number is not valid')
  .required()

export const required = Yup.string().trim().required()

export const selectSingle = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string().required('Field cannot be empty'),
})

export const selectMultiple = Yup.array().of(selectSingle)
