import ReactDOM from 'react-dom'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {DatastoreProvider} from './app/modules/auth/core/Data'

// Styles
import 'quill/dist/quill.snow.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <AuthProvider>
        <DatastoreProvider>
          <AppRoutes />
        </DatastoreProvider>
      </AuthProvider>
    </MetronicI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
