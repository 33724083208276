/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../../core'
import {toAbsoluteUrl, useWindowSize} from '../../../../helpers'
import {AsideCollapsedMenu} from './AsideCollapsedMenu'
import {useAuth} from '../../../../../app/modules/auth'
import {getNameInitials, prettifyUserRole} from '../../../../helpers/utils'
import NameAvatar from '../../../../../app/components/reusable-blocks/NameAvatar'
import {useDatastore} from '../../../../../app/modules/auth/core/Data'
// Styles for collapsing sidebar in supervisor and reader portal
import './aside-collapsed.css'

const AsideCollapsed: FC = () => {
  const {classes} = useLayout()
  const {currentUser} = useAuth()
  const {profileInfo} = useDatastore()
  const windowSize = useWindowSize()

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '), 'aside-collapse')}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        <Link to='/dashboard'>
          <img alt='Logo' className='w-100 logo' src={toAbsoluteUrl('/media/logos/favicon.png')} />
        </Link>
      </div>

      <div
        className='bg-white d-flex justify-content-center'
        style={{
          backgroundImage: 'url("/media/custom/hck-bg.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        {profileInfo?.image && (
          <img
            className='me-4'
            alt='profile'
            src={toAbsoluteUrl(profileInfo?.image)}
            style={{maxHeight: '140px', objectFit: 'cover', zIndex: 20, maxWidth: '87px'}}
          />
        )}
        <div className='d-flex align-items-center mt-15 mb-5'>
          {!profileInfo?.image && (
            <NameAvatar className='symbol-36px' value={getNameInitials(profileInfo?.full_name)} />
          )}
          {windowSize?.width && windowSize?.width < 992 && (
            <div>
              <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                {profileInfo?.full_name}
              </a>
              <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>
                {prettifyUserRole(currentUser?.profile?.user?.user_type)}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className='aside-menu flex-column-fluid position-relative'>
        {/* <AsideMenu asideMenuCSSClasses={classes.asideMenu} /> */}
        <AsideCollapsedMenu asideMenuCSSClasses={classes.asideMenu} />
        <img
          src='/media/custom/hck-bg-white.svg'
          alt='hck logo'
          width={180}
          className='position-absolute left-0 d-none d-lg-inline'
          style={{bottom: 100, transform: 'rotate(25deg)', opacity: 0.4, zIndex: -10}}
        />
      </div>
    </div>
  )
}

export {AsideCollapsed}
