import {FC, useState, useEffect, useContext} from 'react'
import * as Yup from 'yup'
import {
  email,
  number,
  phone,
  required,
  selectSingle,
} from '../../../../../../../_metronic/helpers/yupSchema'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import ImageUploader from '../../../../../portal/common/components/ImageUploader'
import InputFormik from '../../../../../../components/ui/InputFormik'
import AjaxSelect from '../../../../../../components/ui/AjaxSelect'
import AjaxButton from '../../../../../../components/ui/AjaxButton'
import InputValidationMessage from '../../../../../../components/ui/InputValidationMessage'
import {CustomCreatableSelect} from '../../../../../../components/reusable-blocks/CustomSelect'
import {selectStyle} from '../../../../../../../_metronic/helpers/SelectStyle'
import {
  exportFromDropdownToString,
  splitLanguagesForDropdown,
  programmingLanguages,
} from '../../../../../../../_metronic/helpers/utils'
import WYSIWYG from '../../../../components/WYSIWIG'
// import CancelFormButton from '../../../../../../components/reusable-blocks/CancelFormButton'
import {ID} from '../../../../../../../_metronic/helpers'
// import {DrawerIdContext} from '../../UsersList'
import {DrawerIdContext} from '../../../../../context/DrawerContext'
import {getUserById} from '../../../users-list/core/_requests'
import {AxiosError} from 'axios'
import {
  createErrorDialog,
  createUpdatedDialogCustomBtn,
} from '../../../../../../helpers/confirmation-dialog'
import {updateData} from '../../../../../../helpers/api'

interface props {
  id: ID
}

export const EditDrawerBtn: FC<props> = ({id = undefined}) => {
  const {setDrawerUserId} = useContext(DrawerIdContext)
  return (
    <div>
      {/* Drawer Trigger */}
      {/* <button id='kt_edit_drawer_button' className='btn btn-primary'>
        Toggle basic drawer
      </button> */}

      <span
        id='kt_edit_drawer_button'
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setDrawerUserId(id)
        }}
      >
        <span className='svg-icon svg-icon-3'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              opacity='0.3'
              d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
              fill='currentColor'
            />
            <path
              d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
              fill='currentColor'
            />
          </svg>
        </span>
      </span>

      {/* Drawer View */}
      {/* <div
        // id='kt_drawer_example_basic'
        id='kt_edit_drawer'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-activate='true'
        // data-kt-drawer-toggle='#kt_drawer_example_basic_button'
        data-kt-drawer-toggle='#kt_edit_drawer_button'
        // data-kt-drawer-close='#kt_drawer_example_basic_close'
        data-kt-drawer-width="{default:'300px', 'sm': '500px', 'md': '600px', 'lg': '700px', 'xl': '900px', 'xxl': '1100px'}"
      >
        ...
      </div> */}
    </div>
  )
}

export const EditDrawerView = () => {
  const {drawerUserId} = useContext(DrawerIdContext)

  const studentSchema = Yup.object().shape({
    Email: email,
    Phone: phone,
    'Full Name': required,
    'University ID': number,
    'Project Title': required,
    Description: required,
    Group: selectSingle,
    Supervisor: selectSingle,
    Image: Yup.string(),
    // Languages: Yup.object(),
  })

  type FormikValues = {
    Email: string
    Phone: string
    'Full Name': string
    'University ID': string
    'Project Title': string
    Image: string
    Languages: {label: string; value: string}[] | undefined
    Description: string
    Group: {label: string; value: string}
    Supervisor: {label: string; value: string}
  }

  // WYSIWYG
  let quill: any
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setQuill = (q: any) => (quill = q)
  const [clearText, setClearText] = useState<boolean>(false)
  const [editDescValue, setEditDescValue] = useState<string>('')

  // Scroll to top after WYSIWYG editor is filled
  useEffect(() => {
    if (editDescValue) {
      document.getElementById('kt_edit_drawer')?.scroll(0, 0)
    }
  }, [editDescValue])

  useEffect(() => {
    if (drawerUserId) {
      getUserById(drawerUserId.toString())
        .then((res) => {
          formik.setValues({
            Email: res?.user.username || '',
            Phone: res?.phone_number?.toString() || '',
            'Full Name': res?.user.full_name || '',
            Image: res?.user_image || '',
            'Project Title':
              (res!.student_project!.length > 0 && res!.student_project![0].name) || '',
            'University ID': res?.university_id || '',
            Description:
              (res!.student_project!.length > 0 && res!.student_project![0].description) || '',
            Group: {value: res!.section!.id!.toString(), label: res!.section!.name!},
            Supervisor: {
              label: res!.supervisor!.user!.full_name!,
              value: res!.supervisor!.id!.toString(),
            },
            Languages: splitLanguagesForDropdown(res?.student_project![0].language),
          })
          // Set value on the WYSIWIG when edit
          setEditDescValue(
            (res!.student_project!.length > 0 && res!.student_project![0].description) || ''
          )
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 404) {
            createErrorDialog('No such data found.')
          } else {
            createErrorDialog('Unexpected error occured.')
          }
        })
    } else {
      formik.resetForm({})
    }
    // eslint-disable-next-line
  }, [drawerUserId])

  const formik = useFormik<FormikValues>({
    validationSchema: studentSchema,
    onSubmit: async (values, {resetForm}) => {
      const newLang = values?.Languages?.map((d) => ({label: d?.label, value: d?.value}))
      values.Languages = newLang
      let payload = new FormData()
      payload.append('phone_number', values.Phone)
      payload.append('university_id', values['University ID'])
      payload.append('description', values.Description)
      payload.append('project', values['Project Title'])
      payload.append('college_id', values.Email.substring(0, values.Email.indexOf('@')))
      payload.append('full_name', values['Full Name'])
      payload.append('section', values.Group.value)
      payload.append('email', values.Email)
      payload.append('language', exportFromDropdownToString(values.Languages))
      payload.append('description', values.Description)
      payload.append('section', values.Group.value)
      if (drawerUserId) {
        payload.append('user.username', values.Email)
        payload.append('user.full_name', values['Full Name'])
        payload.append('supervisor_id', values.Supervisor.value)
        if (typeof values.Image === 'object') payload.append('user_image', values.Image)
        else payload.append('user_image', '')
        await updateData(`/student/${drawerUserId}/`, payload)
          // .then(() => createUpdatedDialog())
          .then(() => createUpdatedDialogCustomBtn('kt_edit_drawer_close'))
          .catch((e) => {
            if (e) createErrorDialog('Unexpected error occured.')
          })
      }
    },
    initialValues: {
      Email: '',
      Phone: '',
      'Full Name': '',
      'University ID': '',
      'Project Title': '',
      Image: '',
      Languages: undefined,
      Description: '',
      Group: {
        label: '',
        value: '',
      },
      Supervisor: {
        label: '',
        value: '',
      },
    },
  })

  return (
    <div
      id='kt_edit_drawer'
      className='bg-white row'
      data-kt-drawer='true'
      data-kt-drawer-activate='true'
      data-kt-drawer-toggle='#kt_edit_drawer_button'
      data-kt-drawer-close='#kt_edit_drawer_close'
      //   data-kt-drawer-width="{default:'300px', 'sm': '500px', 'md': '600px', 'lg': '700px', 'xl': '900px', 'xxl': '1100px'}"
      data-kt-drawer-width="{default:'300px', 'sm': '400px', 'md': '500px', 'lg': '600px'}"
    >
      <div className='col-xl-12 pt-5'>
        <div className='card card-xl-stretch mb-5 mb-xl-10'>
          <div className='card-header position-relative'>
            <div
              id='kt_edit_drawer_close'
              className='btn btn-icon btn-active-light-primary position-absolute w-30px h-30px w-md-40px h-md-40px'
              style={{zIndex: 99, right: '0px', top: '10px'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr088.svg' className='svg-icon-1' />
            </div>
            <div className='card-title'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder text-dark'>Enter Student Details</span>
                <span className='text-gray-400 mt-1 fw-bold fs-6'>
                  Please make sure the entered informations are correct and up-to-date.
                </span>
              </h3>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='card-body blockui'>
              {/* {id && formik.values.Email === '' && (
                <div className='blockui-overlay ' style={{zIndex: 1}}>
                  <div className='blockui-message'>
                    <span className='spinner-border text-primary' /> Loading...
                  </div>
                </div>
              )} */}
              <ImageUploader className='justify-content-center' formik={formik} />
              <div className='row'>
                <div className='fv-row mb-9 fv-plugins-icon-container col-md-6'>
                  <InputFormik
                    formik={formik}
                    label='Full Name'
                    placeholder='e.g. Anil Poudyal'
                    isRequired
                  />
                </div>
                <div className='fv-row mb-9 fv-plugins-icon-container col-md-6'>
                  <InputFormik
                    formik={formik}
                    label='Email'
                    type='email'
                    placeholder='e.g. np03a180011@heraldcollege.edu.np'
                    isRequired
                    // disabled={id !== undefined}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='fv-row mb-9 fv-plugins-icon-container col-md-6'>
                  <InputFormik
                    formik={formik}
                    label='University ID'
                    placeholder='e.g. 2040309'
                    isRequired
                  />
                </div>
                <div className='fv-row mb-9 fv-plugins-icon-container col-md-6'>
                  <InputFormik
                    formik={formik}
                    label='Phone'
                    placeholder='e.g. 98XXXXXXXX'
                    isRequired
                  />
                </div>
              </div>
              <div className='row'>
                <div className='fv-row mb-9 fv-plugins-icon-container col-md-6'>
                  <label className='fs-6 fw-bold required mb-2'>Group</label>
                  <AjaxSelect label='Group' formik={formik} url='/org/section/?limit=100' />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
                <div className='fv-row mb-9 fv-plugins-icon-container col-md-6'>
                  <label className='fs-6 fw-bold required mb-2'>Supervisor</label>
                  <AjaxSelect label='Supervisor' formik={formik} url='/supervisor/?limit=100' />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              </div>
              <div className='d-flex flex-column mb-9 fv-row'>
                <InputFormik
                  formik={formik}
                  label='Project Title'
                  placeholder='e.g. Fraud Detection System'
                  isRequired
                >
                  <div className='text-gray-400 fs-8'>
                    Enter the topic of that the student has chosen.
                  </div>
                </InputFormik>
              </div>
              <div className='d-flex flex-column mb-9 fv-row'>
                <label className={clsx('fs-6 fw-bold mb-2', 'required')}>Languages</label>
                {/* <CustomSelect
                  isMulti
                  styles={selectStyle}
                  options={programmingLanguages()}
                  value={formik.values.Languages}
                  onBlur={() => {}}
                  onChange={(val) => {
                    formik.setFieldValue('Languages', val)
                  }}
                /> */}
                <CustomCreatableSelect
                  isMulti
                  styles={selectStyle}
                  options={programmingLanguages()}
                  value={formik.values.Languages}
                  onBlur={() => {}}
                  onChange={(val: any) => {
                    formik.setFieldValue('Languages', val)
                  }}
                />
                {formik.touched['Languages'] && formik.errors['Languages'] && (
                  <InputValidationMessage>{formik.errors['Languages']}</InputValidationMessage>
                )}
              </div>
              <div className='d-flex flex-column mb-9 fv-row'>
                <label className='fs-6 fw-bold mb-2'>
                  <span className='required'>Description</span>
                  <div className='text-gray-400 fs-8'>
                    Explain in brief what the project is about.
                  </div>
                </label>
                <WYSIWYG
                  clearText={{value: clearText, setClearText: setClearText}}
                  setQuill={setQuill}
                  formik={formik}
                  // initialValue={id ? formik?.values?.Description : ''}
                  initialValue={editDescValue}
                />
                {formik.touched.Description && formik.errors.Description && (
                  <InputValidationMessage>{formik.errors.Description}</InputValidationMessage>
                )}
              </div>
              <div className='d-flex justify-content-end'>
                {/* <CancelFormButton /> */}
                <AjaxButton
                  text={'Save'}
                  loading={formik.isSubmitting}
                  className='btn btn-primary btn-hover-scale'
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
