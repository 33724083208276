import axios from 'axios'
import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import {selectStyle} from '../../../_metronic/helpers/SelectStyle'
import {setupAxios} from '../../modules/auth'
import InputValidationMessage from './InputValidationMessage'

setupAxios(axios)

interface Props {
  url: string
  formik?: any
  label: string
  placeholder?: string
}

const AjaxSelect: FC<Props> = ({url, formik, label, placeholder}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    setLoading(true)
    axios
      .get(url, {baseURL: process.env.REACT_APP_API_URL})
      .then(({data}) => {
        setData(data.results)
        setLoading(false)
      })
      .catch((e) => console.log(e))
  }, [url])

  let options
  if (url.includes('/supervisor/')) {
    // options = data.map(({id, user: {full_name}}) => ({value: id.toString(), label: full_name}))
    options = data.map(({id, user: {full_name}, code}) => ({
      value: id.toString(),
      label: `${full_name} (${code})`,
    }))
  } else if (url.includes('/org/section/')) {
    options = data.map(({id, name}) => ({value: id.toString(), label: name}))
  }
  // console.log(formik.values[label])

  return (
    <>
      <Select
        value={formik.values[label]['label'] && formik.values[label]}
        styles={selectStyle}
        options={options}
        placeholder={placeholder || 'e.g. Aayush Thapa'}
        isLoading={loading}
        isDisabled={loading}
        onBlur={() => formik.setFieldTouched(label, true)}
        onChange={(val) => {
          formik.setFieldValue(label, val)
        }}
      />
      {formik.touched[label] && formik.values[label] && (
        <InputValidationMessage>{formik.errors[label]?.value}</InputValidationMessage>
      )}
    </>
  )
}

export default AjaxSelect
