export const getNameInitials = (fullName?: string) => {
  fullName = fullName?.trim()
  if (!fullName) return '?'
  const nameArr = fullName.split(' ')
  if (nameArr.length === 1) return nameArr[0][0].toUpperCase() + nameArr[0][1].toUpperCase()
  return nameArr[0][0].toUpperCase() + nameArr[nameArr.length - 1][0].toUpperCase()
}

export const getRandomBootstrapColor = () => {
  let array = ['primary', 'danger', 'warning', 'info']
  const randomElement = array[Math.floor(Math.random() * array.length)]
  return randomElement
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const prettifyUserRole = (role?: string) => {
  switch (role) {
    case undefined:
      return ''
    case 'admin':
      return 'FYP Coordinator'
    default:
      return capitalizeFirstLetter(role)
  }
}

export const programmingLanguages = () => [
  {label: 'Python', value: 'Python'},
  {label: 'JavaScript', value: 'JavaScript'},
  {label: 'Flutter', value: 'Flutter'},
  {label: 'Go', value: 'Go'},
  {label: 'Rust', value: 'Rust'},
  {label: '.NET', value: '.NET'},
]

export const stringArrayOfObjects = (payload?: string) => {
  if (!payload) return []
  var newJson = payload.replace(/([a-zA-Z0-9]+?):/g, '"$1":')
  newJson = newJson.replace(/'/g, '"')
  return JSON.parse(newJson) as {label: string; value: string}[]
}

// JS, Flutter
export const splitLanguagesForDropdown = (payload?: string) => {
  if (!payload) return undefined
  const data = payload
  return data.split(',').map((e) => ({label: e.trim(), value: e.trim()}))
}

export const exportFromDropdownToString = (payload?: {label: string; value: string}[]) => {
  if (!payload) return ''
  const lastIndex = payload.length - 1
  let str = ''
  for (let i = 0; i < payload.length; i++) {
    str += payload[i].label
    if (i !== lastIndex) {
      str += ', '
    }
  }
  return str
}
