import {FC, lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DashboardWrapper} from '../pages/reader-dashboard/DashboardWrapper'
import SupervisorsPage from '../pages/reader-dashboard/profile/SupervisorsPage'
import StudentsMarksPage from '../pages/reader-dashboard/marks/StudentsMarksPage'

const ReaderRoutes = () => {
  // const FeedbackPage = lazy(() => import('../pages/supervisor-dashboard/feedbacks/FeedbackPage'))
  const ProfilePage = lazy(() => import('../pages/reader-dashboard/profile/ProfilePage'))
  const FeedbackPage = lazy(() => import('../pages/reader-dashboard/feedbacks/FeedbackPage'))
  const StudentsPage = lazy(() => import('../pages/reader-dashboard/students/StudentsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='feedbacks/*'
          element={
            <SuspensedView>
              <FeedbackPage />
            </SuspensedView>
          }
        />
        <Route
          path='students/*'
          element={
            <SuspensedView>
              <StudentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='marks/*'
          element={
            <SuspensedView>
              <StudentsMarksPage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='supervisors/*'
          element={
            <SuspensedView>
              <SupervisorsPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {ReaderRoutes}
