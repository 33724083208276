// Show routes based on current user's role
export const shouldRoute = (
  currentUserRole?: string,
  resultUserRole?: string,
  //* Temporary Solution (Must be handled from the Backend): Only show students of the current logged in supervisor
  currentSupervisorId?: number,
  resultUserId?: number
  //
) => {
  if (currentUserRole === 'supervisor') {
    if (resultUserRole === 'student') {
      // Temporary Solution (Must be handled from the Backend): Only show students of the current logged in supervisor
      if (currentSupervisorId === resultUserId) return true
      //
    } else {
      return false
    }
  } else if (currentUserRole === 'reader') {
    if (resultUserRole === 'supervisor') {
      return true
    } else if (resultUserRole === 'student') {
      return true
    } else {
      return false
    }
  } else if (currentUserRole === 'admin') {
    if (resultUserRole === 'admin') {
      return true
    } else if (resultUserRole === 'reader') {
      return true
    } else if (resultUserRole === 'supervisor') {
      return true
    } else if (resultUserRole === 'student') {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const searchRoute = (currentUserRole: string, resultUserRole: string, routeId: string) => {
  if (currentUserRole === 'supervisor') {
    if (resultUserRole === 'student') {
      return `/students/${routeId}`
    } else {
      return '#'
    }
  } else if (currentUserRole === 'reader') {
    if (resultUserRole === 'supervisor') {
      return `/supervisors/${routeId}`
    } else if (resultUserRole === 'student') {
      return `/students/${routeId}`
    } else {
      return '#'
    }
  } else if (currentUserRole === 'admin') {
    if (resultUserRole === 'reader') {
      return `/readers/${routeId}`
    } else if (resultUserRole === 'supervisor') {
      return `/supervisors/${routeId}`
    } else if (resultUserRole === 'student') {
      return `/students/${routeId}`
    } else {
      return '#'
    }
  } else {
    return '#'
  }
}
