import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {setupAxios} from '../../../../../modules/auth'

import {RootUser, User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/student/`
// const GET_USERS_URL = `${API_URL}/users/query`

setupAxios(axios)

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: string): Promise<RootUser | undefined> => {
  return axios.get(`${USER_URL}${id}/`).then(({data}) => data)
}
const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  return axios.post(`${API_URL}/user/bulk/delete/`, {
    user_type: 'student',
    ids: userIds,
  })
}

const presentAttendanceSelectedUsers = (userIds: Array<string | undefined>): Promise<void> => {
  return axios.post(`${API_URL}/attendance/`, {
    student_list: userIds,
  })
}

const absentAttendanceSelectedUsers = (userIds: Array<string | undefined>): Promise<void> => {
  return axios.post(`${API_URL}/attendance/remove/`, {
    student_list: userIds,
  })
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  presentAttendanceSelectedUsers,
  absentAttendanceSelectedUsers,
}
