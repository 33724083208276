import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  icon: string
  title: string
  subtitle: string
  to?: string
  titleStyles?: string
}

const DashboardStatItem: FC<Props> = (props) => {
  return (
    <div className='col-xl-4'>
      <Link to={props.to || '/'} className='card bg-body hoverable card-xl-stretch mb-xl-8'>
        <div className='card-body'>
          <KTSVG className='svg-icon svg-icon-primary svg-icon-3x ms-n1' path={props.icon} />
          <div className={`text-gray-900 fw-bolder fs-2 mb-2 mt-5 ${props?.titleStyles || ''}`}>
            {props.title}
          </div>
          <div className='fw-bold text-gray-400'>{props.subtitle}</div>
        </div>
      </Link>
    </div>
  )
}

export default DashboardStatItem
