import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {checkIsActive, KTSVG, useWindowSize} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideCollapsedMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  // Window Size
  const windowSize = useWindowSize()

  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>{title}</Tooltip>}>
      <div className='menu-item' /* title={title} */>
        <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && aside.menuIcon === 'svg' && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
          {/* <span className='menu-title'>{title}</span> */}
          {windowSize?.width && windowSize?.width < 992 && (
            <span className='menu-title'>{title}</span>
          )}
        </Link>
        {children}
      </div>
    </OverlayTrigger>
  )
}

export {AsideCollapsedMenuItem}
