/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import AssignedStudentsTable from '../../../_metronic/partials/widgets/custom/AssignedStudentsTable'
import {getAllSections, getAllSupervisors} from '../../helpers/api'
import {useAuth} from '../../modules/auth'
import {useDatastore} from '../../modules/auth/core/Data'
import {BarChart} from '../../modules/supervisors/components/BarChart'
import {SupervisorProfileModel} from '../../portals/supervisors/_models'
import MostAttentiveStudents from './components/MostAttentiveStudents'
import UnattentiveStudents from './components/UnattentiveStudents'

const DashboardPage: FC = () => {
  const {setSupervisors, setGroups} = useDatastore()
  const {currentUser} = useAuth()
  const supervisor = currentUser as SupervisorProfileModel

  useEffect(() => {
    getAllSupervisors().then((value) => setSupervisors(value))
    getAllSections().then((value) => setGroups(value))
    // eslint-disable-next-line
  }, [])

  const topAttenders = [...supervisor.profile.students].sort(
    (a, b) => b.attendance_count - a.attendance_count
  )

  const bottomAttenders = [...supervisor.profile.students].sort(
    (a, b) => a.attendance_count - b.attendance_count
  )

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <Link to='/students/list' className='card bg-body hoverable card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <KTSVG
                className='svg-icon svg-icon-primary svg-icon-3x ms-n1'
                path='/media/icons/duotune/communication/com014.svg'
              />
              <div className='text-gray-900 fw-bolder fs-2 mb-2 mt-5'>
                {supervisor.profile?.students_count}
              </div>
              <div className='fw-bold text-gray-400'>Assigned Students</div>
            </div>
          </Link>
        </div>
        <div className='col-xl-4'>
          <div className='card bg-body card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <span className='svg-icon svg-icon-primary svg-icon-3x ms-n1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect x={8} y={9} width={3} height={10} rx='1.5' fill='currentColor' />
                  <rect
                    opacity='0.5'
                    x={13}
                    y={5}
                    width={3}
                    height={14}
                    rx='1.5'
                    fill='currentColor'
                  />
                  <rect x={18} y={11} width={3} height={8} rx='1.5' fill='currentColor' />
                  <rect x={3} y={13} width={3} height={6} rx='1.5' fill='currentColor' />
                </svg>
              </span>
              <div className='text-gray-900 fw-bolder fs-2 mb-2 mt-5'>
                {supervisor.profile?.attendance_count}
              </div>
              <div className='fw-bold text-gray-400'>Total Meetings</div>
            </div>
          </div>
        </div>
        <div className='col-xl-4'>
          {/* <a href='#' className='card bg-body hoverable card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <KTSVG
                className='svg-icon svg-icon-primary svg-icon-3x ms-n1'
                path='/media/icons/duotune/communication/com013.svg'
              />
              <div className='text-gray-900 fw-bolder fs-2 mb-2 mt-5'>
                {supervisor.profile?.reader?.user?.full_name}
              </div>
              <div className='fw-bold text-gray-400'>Reader</div>
            </div>
          </a> */}
          <Link
            to={`/reader/${supervisor.profile?.reader?.id}`}
            className='card bg-body hoverable card-xl-stretch mb-xl-8'
          >
            <div className='card-body'>
              <KTSVG
                className='svg-icon svg-icon-primary svg-icon-3x ms-n1'
                path='/media/icons/duotune/communication/com013.svg'
              />
              <div className='text-hover-primary text-gray-900 fw-bolder fs-2 mb-2 mt-5'>
                {supervisor.profile?.reader?.user?.full_name}
              </div>
              <div className='fw-bold text-gray-400'>Reader</div>
            </div>
          </Link>
        </div>
      </div>
      <div className='row gx-10 mb-5 mb-xxl-10'>
        <div className='mt-5 mt-md-0 col-md-6'>
          <UnattentiveStudents
            payload={bottomAttenders}
            total_meetings={supervisor.profile.attendance_count}
          />
          <div className='mb-5 mb-xxl-10'></div>
          <MostAttentiveStudents
            payload={topAttenders}
            total_meetings={supervisor.profile.attendance_count}
          />
        </div>
        <AssignedStudentsTable />
      </div>
      {currentUser?.profile?.meeting_log && (
        <BarChart
          // chartData={currentUser?.profile?.meeting_log?.slice(0, 7)?.map((e: any) => e[1])}
          chartData={Object.values(currentUser?.profile?.meeting_log)?.slice(0, 7)}
          xAxis={Object.values(currentUser?.profile?.meeting_log)
            ?.map((it, idx) => `Week ${idx + 1}`)
            ?.reverse()}
        />
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
