import {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../app/modules/auth'
import {getUserByToken} from '../../../../app/modules/auth/core/_requests'
// import {useQueryResponse} from '../../../../app/pages/supervisor-dashboard/user-management/users-list/core/QueryResponseProvider'
import {
  absentAttendanceSelectedUsers,
  presentAttendanceSelectedUsers,
} from '../../../../app/pages/supervisor-dashboard/user-management/users-list/core/_requests'
import {SupervisorProfileModel} from '../../../../app/portals/supervisors/_models'
import {KTSVG /* QUERIES */} from '../../../helpers'

const AssignedStudentsTable = () => {
  const {/* currentUser, */ auth} = useAuth()
  const queryClient = useQueryClient()
  // const {query} = useQueryResponse()
  // const supervisor = currentUser as SupervisorProfileModel
  // Checkbox
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [isCheck, setIsCheck] = useState<Array<string | undefined>>([])

  const {data: response} = useQuery(`user-profile`, () => {
    if (auth?.access) {
      return getUserByToken(auth?.access)
    }
  })

  const profileData = response?.data as SupervisorProfileModel

  const presentStudents = useMutation(() => presentAttendanceSelectedUsers(isCheck), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`user-profile`])
      setIsCheck([])
      setIsCheckAll(false)
      Swal.fire({
        icon: 'success',
        title: 'Done',
        text: 'Attendance Done',
      })
    },
  })

  const absentStudents = useMutation(() => absentAttendanceSelectedUsers(isCheck), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`user-profile`])
      setIsCheck([])
      setIsCheckAll(false)
    },
  })

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(profileData?.profile?.students?.map((d) => d?.id?.toString()))
    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleClick = (e: any) => {
    const {value, checked} = e.target
    setIsCheck([...isCheck, value])
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== value))
    }
  }

  const handlePresent = async () => {
    try {
      await presentStudents.mutateAsync()
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong',
      })
    }
  }

  const handleAbsent = async () => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, absent them',
      icon: 'warning',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          await absentStudents.mutateAsync()
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong',
          })
        }
      }
    })
  }

  return (
    <div className='col-md-6'>
      <div className='card shadow-sm mb-5' style={{height: '565px', overflow: 'scroll'}}>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            {profileData?.profile?.supervisor_attenence[0]?.date && (
              <div className='d-flex align-items-center rounded py-5 px-5 me-2 bg-light-warning'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon svg-icon-2x svg-icon-warning me-5'
                />
                <div className='text-gray-700 fw-bold fs-6'>
                  Last Attendance Date: {profileData?.profile?.supervisor_attenence[0]?.date}
                </div>
              </div>
            )}

            {isCheck?.length ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={handlePresent}
                  disabled={presentStudents?.isLoading}
                >
                  Present
                </button>
                <button
                  type='button'
                  className='btn btn-danger ms-2'
                  onClick={handleAbsent}
                  disabled={absentStudents?.isLoading}
                >
                  Absent
                </button>
              </div>
            ) : null}
          </div>
          <div className='hover-scroll-overlay-y position-relative'>
            <table className='table align-middle table-row-bordered gy-4'>
              <thead
                className='border-gray-200 fs-5 fw-bold sticky-top bg-white'
                style={{zIndex: 1}}
              >
                <tr>
                  <th className='w-10px pe-2 rounded-start'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={isCheck?.length === profileData?.profile?.students?.length}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </th>
                  <th className='ps-4 w-125px'>Student Id</th>
                  <th>Student Name</th>
                  <th>Attendance</th>
                  <th>This week</th>
                </tr>
              </thead>
              <tbody className='fw-6 fw-bold text-gray-600'>
                {profileData?.profile?.students?.map((e, idx) => (
                  <tr key={idx}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={isCheck?.includes(e?.id?.toString())}
                          value={e?.id}
                          onChange={handleClick}
                        />
                      </div>
                    </td>
                    <td className='ps-4'>
                      <span className='badge badge-light-success fs-7 fw-bolder'>
                        {e.university_id}
                      </span>
                    </td>
                    <td>
                      <Link className='text-muted text-hover-primary' to={`/students/${e?.id}`}>
                        {e.user?.full_name}
                      </Link>
                    </td>
                    <td>{e.attendance_count}</td>
                    <td>
                      {e?.present_in_last_attendance ? (
                        <span className='badge badge-light-success fs-7 fw-bolder'>Present</span>
                      ) : (
                        <span className='badge badge-light-danger fs-7 fw-bolder'>Absent</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignedStudentsTable
